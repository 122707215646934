import { useEffect } from "react";
import { useAccount, useChainId, useProvider, useSigner, useSwitchNetwork } from "wagmi";
import { usePaintSwap } from "../hooks/usePaintSwap";
import { ethers } from "ethers";
import { gql, useQuery } from "@apollo/client";
import { usePairFactory } from "../hooks/usePairFactory";
import { usePair } from "../hooks/usePairs";


const GET_PAIRS = gql`
{
  pairs(first: 1000) {
    id
    owner
		assetRecipient,
		erc20Contract
    nft,
		poolType,
    bondingCurveAddress,    
  }
}
`;

export const PaintSwap: React.FC = () => {
  const { address } = useAccount();
  const { data: signer } = useSigner();
  const { initialize ,getCollections} = usePaintSwap();
  const { load } = usePair();
  const chainId = useChainId();
  const { data } = useQuery(GET_PAIRS);
  useEffect(() => {
    const fantomProvider = new ethers.providers.JsonRpcProvider(
      "https://rpc.ftm.tools/"
    );
    address&&initialize(address as any, signer && chainId === 250 ? signer : fantomProvider);
  }, [address, chainId, initialize, signer]);
  useEffect(()=>{
    getCollections();
  },[])
  const { switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    console.log("NICE", chainId);
    if (chainId !== 250) {
      switchNetwork && switchNetwork(250);
    }
  }, [chainId, switchNetwork])
  useEffect(() => {
    data && data.pairs && load(data.pairs);
  }, [data, load]);
  return <></>;
};

import { gql, useQuery } from "@apollo/client";
import {
  IonAvatar,
  IonChip,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonText
} from "@ionic/react";
import { swapHorizontal } from "ionicons/icons";
import React from "react";
import { formatTokenPrice, icons, tokens } from "../components/PoolList";
import { usePaintSwap } from "../hooks/usePaintSwap";
import { BigNumber } from "ethers";
import { timeStamp } from "console";
import { useBlockNumber } from "wagmi";

const GET_SWAPS = gql`
  {
    swaps(first: 50,orderBy: timestamp, orderDirection: desc) {
      id
      nft
      pair
      txHash
      cost
      nftIds
      price
      timestamp
      token
    }
  }
`;
export function timeSince(since:number) {
  console.log(since);
  var seconds = Math.floor((new Date().getTime()/1000 - since) );

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

const SwapImage: React.FC<{ nft: string; id: string }> = ({ nft, id }) => {
  const { getFallbackImage } = usePaintSwap();

  return (
    <IonAvatar>
      <IonImg src={getFallbackImage(nft, id)} />
    </IonAvatar>
  );
};

const SwapInfo: React.FC<{
  id: string;
  nft: string;
  pair: string;
  txHash: string;
  cost: string;
  nftIds: string;
  price: string;
  token: string;
  timestamp:string;
}> = ({ id, nft, pair, txHash, cost, nftIds, price, token ,timestamp}) => {
  const collection = usePaintSwap((x) =>
    x.collections.find((x) => x.address.toLowerCase() === nft.toLowerCase())
  );
  console.log(timestamp);
  return (
    <IonItem href={"https://ftmscan.com/tx/" + txHash} target="_new">
      <IonChip>
        <IonAvatar>
          {icons[tokens[token.toLowerCase()]] && (
            <IonImg src={icons[tokens[token.toLowerCase()==='0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83'.toLowerCase()?"fantom":token.toLowerCase()]]} />
          )}
        </IonAvatar>
        {/* {formatTokenPrice(BigNumber.from(price).mul(BigNumber.from(nftIds.split(",").length)))} */}
        <IonText>{tokens[token.toLowerCase()==='0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83'.toLowerCase()?"fantom":token.toLowerCase()]}</IonText>
      </IonChip>
      <IonIcon icon={swapHorizontal} />
      <IonChip>
        {collection?.name}{" "}
        {nftIds.split(",").map((id) => (
          <>
            {id}
            <SwapImage id={id} nft={nft} />
          </>
        ))}
      </IonChip>
{timeSince(parseInt(timestamp))}
    </IonItem>
  );
};

export const SwapList:React.FC<{pairs?:string[]}> = ({pairs})=>{
  const { data } = useQuery<{ swaps: Array<any> }>(GET_SWAPS);
  const { swaps } = data || { swaps: [] };
  console.log(data,"SWAPS");
  return <> {swaps.filter(x=>typeof pairs!=='undefined'?pairs?.map(x=>x.toLowerCase())?.includes(x.pair.toLowerCase()):true).map((x: any) => {
    return (
      <SwapInfo
        timestamp={x.timestamp}
        cost={x.cost}
        id={x.id}
        nftIds={x.nftIds}
        price={x.price}
        txHash={x.txHash}
        nft={x.nft}
        token={x.token}
        pair={x.id}
        key={x.id}
      />
    );
  })}
</>
}

export const Swaps: React.FC = () => {
  return (
    <IonContent>
      <IonGrid>
<SwapList/>
      </IonGrid>
    </IonContent>
  );
};

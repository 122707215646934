import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";

import {
  analyticsOutline,
  analyticsSharp,
  cogOutline,
  cogSharp,
  flameOutline,
  flameSharp,
  informationCircleOutline,
  informationCircleSharp,
  sparklesOutline,
  timeOutline,
  timeSharp,
  walletOutline,
  walletSharp,
  waterOutline,
  waterSharp,
} from "ionicons/icons";
import { useLocation } from "react-router-dom";
import "./Menu.css";
import { useAccount } from "wagmi";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

export const appPages: AppPage[] = [
  {
    title: "Info",
    url: "/info",
    iosIcon: informationCircleOutline,
    mdIcon: informationCircleSharp,
  },
  {
    title: "Pools",
    url: "/pools",
    iosIcon: waterOutline,
    mdIcon: waterSharp,
  },
  {
    title: "Wallet",
    url: "/wallet",
    iosIcon: walletOutline,
    mdIcon: walletSharp,
  } , {
    title: "History",
    url: "/history",
    iosIcon: timeOutline,
    mdIcon: timeSharp,
  },
  // {
  //   title: "Stats",
  //   url: "/stats",
  //   iosIcon: analyticsSharp,
  //   mdIcon: analyticsOutline,
  // },

  {
    title: "My Pools",
    url: "/my-pools",
    iosIcon: cogOutline,
    mdIcon: cogSharp,
  }
  ,
  // {
  //   title: "Tarot",
  //   url: "/tarot",
  //   iosIcon: sparklesOutline,
  //   mdIcon: sparklesOutline,
  // },
  {
    title: "Burn",
    url: "/burn",
    iosIcon: flameOutline,
    mdIcon: flameSharp,
  },

];

const Menu: React.FC = () => {
  const location = useLocation();
  const {address} = useAccount()
  const filtered = !address?appPages.filter(x=>x.url!=='/wallet'&&x.url!=='/my-pools'):appPages
  
  return (
    <IonMenu contentId="main" type="overlay">
      <IonList>
        {filtered.map((appPage, index) => {
          return (
            <IonMenuToggle key={index} autoHide={false}>
              <IonItem
                className={location.pathname === appPage.url ? "selected" : ""}
                routerLink={appPage.url}
                routerDirection="none"
                lines="none"
                detail={false}
              >
                <IonIcon
                  slot="start"
                  ios={appPage.iosIcon}
                  md={appPage.mdIcon}
                />

                <IonLabel>{appPage.title}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          );
        })}
      </IonList>
    </IonMenu>
  );
};

export default Menu;

import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonItemDivider,
  IonList,
  IonProgressBar,
  IonRow,
  IonTitle,
} from '@ionic/react'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils.js'
import {
  arrowBack,
  arrowForward,
  settingsOutline,
  swapHorizontal,
  swapHorizontalOutline,
} from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useAccount, useBalance, useSigner } from 'wagmi'
import { useERC20 } from '../hooks/useERC20'
import { useERC721 } from '../hooks/useERC721'
import { useNotification } from '../hooks/useNotifications'
import { usePaintSwap } from '../hooks/usePaintSwap'
import { usePairFactory } from '../hooks/usePairFactory'
import { usePairRouter } from '../hooks/usePairRouter'
import { usePair } from '../hooks/usePairs'
import { useTransactionModal } from '../hooks/useTransactionModal'
import { Page } from '../pages/Page'
import { PairEth__factory } from '../types/ethers-contracts/factories/PairEth__factory'
import { ApproveNFTButton } from './ApproveButton'
import { ApproveERC20Button } from './ApproveERC20Button'

export const tokens: Record<string, string> = {
  fantom: 'ftm',
  '0x3edA36088b931098e8E472748840b3dF78268c72': '⚗️$potion',
  '0x208234F4f8B1bBEA59cfDc38666141654e851DCe': '🕯️$candle',
  '0xb715F8DcE2F0E9b894c753711bd55eE3C04dcA4E': '🐕$conk',
  '0x8503eb4a136bdbeb323e37aa6e0fa0c772228378': '$fBomb',
  '0x3fd3a0c85b70754efc07ac9ac0cbbdce664865a6': '$equal',
  '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75': '💵USDC',
}
export const formatTokenPrice = (amount: BigNumber) => {
  if (amount.gte(BigNumber.from(10).pow(27))) {
    const inBillions = amount.div(BigNumber.from(10).pow(9))
    const inBillionsSplit = formatEther(inBillions).split('.')
    return inBillionsSplit[0] + '.' + inBillionsSplit[1].substring(0, 2) + 'B'
  } else {
    const priceString = formatEther(amount)
    const index = priceString.indexOf('.')
    const toPrecision2 = priceString.substring(0, index + 2)
    return toPrecision2
  }
}

const PoolInfo: React.FC<{ pair: string; pageSize?: number }> = ({
  pair,
  pageSize = 5,
}) => {
  const { data: signer } = useSigner()
  const { initialize } = usePair()
  const {
    initialize: initRouter,
  } = usePairRouter()
  const heldIds = usePair((x) => x.heldIds[pair])
  const { address: walletAddress } = useAccount()
  const {collection,erc20:token,owner} = usePair((x) => x.getInfo(pair.toLowerCase()));
  const inss=usePair(x=>x.getInfo(pair));

  const nftInstance = useERC721((x) => x.instance[collection])

  const connectERC20 = useERC20((x) => x.initialize)
  const { post: postTx } = useTransactionModal()
  const { initialize: connectErc721 } = useERC721()
  const { data: ftmBalance } = useBalance({ address: pair as any })
  const { instances } = useERC20()
  const tokenInstance = instances[token]
  const [pairTokenBalance, setPairTokenBalance] = useState<BigNumber>()
  useEffect(() => {
    signer && initialize(pair, signer)
    signer && initRouter(signer)
  }, [signer, initRouter, initialize, pair])
  useEffect(() => {
    signer && collection && connectErc721(collection, signer)
  }, [signer, collection, connectErc721])

  useEffect(() => {
    signer && token && token !== 'fantom' && connectERC20(token, signer)
    if (token === 'fantom') {
      ftmBalance && setPairTokenBalance(ftmBalance?.value)
    }
  }, [signer, token, connectERC20, ftmBalance])
  useEffect(() => {
    signer &&
      tokenInstance &&
      tokenInstance.balanceOf(pair).then((response) => {
        setPairTokenBalance(response)
      })
  }, [signer, tokenInstance, pair])

  useEffect(() => {
    signer &&
      tokenInstance &&
      tokenInstance.balanceOf(pair).then((response) => {
        setPairTokenBalance(response)
      })
  }, [signer, tokenInstance, pair])

  useEffect(() => {}, [heldIds, nftInstance])
  const { getFallbackImage, fetchAddressInventory } = usePaintSwap()
  const { inventory, collections, fetchCollectionInfo } = usePaintSwap()
  const nftInventory = inventory[collection]
  const pairInventory = inventory[pair + '-' + collection] || []
  useEffect(() => {
    walletAddress && fetchCollectionInfo(walletAddress, [pair])
  }, [pair, walletAddress, fetchCollectionInfo])
  useEffect(() => {
    signer && initialize(pair, signer)
  }, [pair, signer, initialize])
  const instance = usePair((x) => x.instance[pair])
  const pairTokens = useERC20((x) => x.instances)
  const [tokenAmount, setTokenAmount] = useState<BigNumber>()
  const [newSpotPrice, setSpotPrice] = useState<string>('')
  const [newDelta, setDelta] = useState<string>('')
  const [newFee, setFee] = useState<string>('')

  const currency = token ? tokens[token] : 'ftm'
  useEffect(() => {
    if (typeof nftInventory === 'undefined' && collection && walletAddress) {
      fetchCollectionInfo(walletAddress, [collection])
    }
  }, [walletAddress, collection, nftInventory, fetchCollectionInfo])
  useEffect(() => {
    if (typeof nftInventory === 'undefined' && collection && walletAddress) {
      fetchAddressInventory(pair, collection)
    }
  }, [
    pair,
    walletAddress,
    collection,
    nftInventory,
    fetchAddressInventory,
    fetchCollectionInfo,
  ])
  useEffect(() => {
    tokenInstance &&
      tokenInstance.balanceOf(pair).then((amnt) => {
        setTokenAmount(amnt)
      })
  }, [tokenInstance])

  const info = collections.find(
    (x) =>
      x.address.toLowerCase() ===
      (collection || '').toLowerCase(),
  )

  return (
    <IonCard>
      <IonCardTitle>
        <a href={'/admin/' + pair}>
          {info?.name||pair} pool
        </a>
      </IonCardTitle>
      {pairTokenBalance?.gt(0) && (
        <IonBadge
          color={
            pairTokenBalance && pairTokenBalance.eq(0) ? 'light' : 'primary'
          }
        >
          {pairTokenBalance && formatTokenPrice(pairTokenBalance)} {currency}
        </IonBadge>
      )}
      <IonBadge color={pairInventory.length === 0 ? 'light' : 'tertiary'}>
        {pairInventory.length} nfts
      </IonBadge>
      {/* <IonItemDivider />
      <IonItem href={'https://ftmscan.com/address/' + pair}>
        Pair:{pair}
      </IonItem> */}
      {/* <IonItem href={'https://ftmscan.com/address/' + collection}>
        Collection:{collection}
      </IonItem>
      <IonItem href={'https://ftmscan.com/address/' + token}>
        Token:{tokens[token]}
      </IonItem> */}
      {/* <IonItem>
        <IonInput
          onIonChange={(e) => {
            setSpotPrice(e.detail.value || '')
          }}
          type="number"
          placeholder="Adjust Spot Price"
        ></IonInput>
        <IonButton
          onClick={() => {
            instance.changeSpotPrice(
              BigNumber.from(newSpotPrice).mul(BigNumber.from(10).pow(18)),
            )
          }}
        >
          {' '}
          Set price
        </IonButton>
      </IonItem> */}
      {/* <IonItem>
        <IonInput
          onIonChange={(e) => {
            setDelta(e.detail.value || '')
          }}
          type="number"
          placeholder="Adjust Price Delta"
        ></IonInput>
        <IonButton
          onClick={() => {
            instance.changeDelta(
              BigNumber.from(newDelta).mul(BigNumber.from(10).pow(18)),
            )
          }}
        >
          {' '}
          Set delta
        </IonButton>
      </IonItem>
      <IonItem>
        <IonInput
          onIonChange={(e) => {
            setFee(e.detail.value || '')
          }}
          type="number"
          placeholder="Adjust Pool Fee"
        ></IonInput>
        <IonButton
          onClick={() => {
            instance.changeFee(BigNumber.from(newFee))
          }}
        >
          {' '}
          Set Fee
        </IonButton>
      </IonItem> */}
      {heldIds && heldIds.length > 0 && (
        <IonButton
          fill="clear"
          color="danger"
          onClick={() => {
            walletAddress && instance.withdrawERC721(collection, heldIds)
          }}
        >
          Withdraw NFTS {heldIds && heldIds.length}
        </IonButton>
      )}
      {token && walletAddress && signer && tokenAmount && tokenAmount.gt(0) && (
        <IonButton
          fill="clear"
          color="danger"
          onClick={() => {
            instance.withdrawERC20(token, tokenAmount)
          }}
        >
          Withdraw Token {tokenAmount && formatEther(tokenAmount)}
        </IonButton>
      )}
      {token === 'fantom' && ftmBalance?.value.gt(0) && walletAddress && (
        <IonButton
          color="danger"
          onClick={() => {
            PairEth__factory.connect(pair, signer!).withdrawAllETH()
          }}
        >
          Withdraw {ftmBalance && ftmBalance.formatted} FTM
        </IonButton>
      )}
    </IonCard>
  )
}

export const MyPools: React.FC = () => {
  const { info,  } = usePair()
  const {pairs,fetchPairs}=usePairFactory();
  const {address} = useAccount();
  useEffect(()=>{
    fetchPairs();
  },[fetchPairs])
  const myPairs = Object.entries(info).filter(([_,{owner}])=>owner?.toLowerCase()===address?.toLowerCase()).map(x=>x[0]?.toLowerCase())
  const sm = window.innerWidth < 576
  const md = window.innerWidth < 768
  console.log(pairs);
  console.log(pairs.filter(x=>myPairs.includes(x?.toLowerCase())));
  useEffect(()=>{
    fetchPairs()
    
  },[])
  return (
      <IonList>
        {pairs.filter(x=>myPairs.includes(x.toLowerCase())).map((x,i) => {
          return <PoolInfo key={i} pair={x} pageSize={sm ? 3 : md ? 4 : 5} />
        })}
      </IonList>
  )
}

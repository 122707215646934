import {
  IonAvatar,
  IonButton,
  IonChip,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
} from '@ionic/react'
import { skullOutline } from 'ionicons/icons'
import { useEffect } from 'react'
import {
  Area,
  AreaChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useAccount, useSigner } from 'wagmi'
import {
  fromFloat,
  percentFromFloat,
  useBondingCurve,
} from '../hooks/useBondingCurve'
import { useNotification } from '../hooks/useNotifications'
import { usePaintSwap } from '../hooks/usePaintSwap'
import { usePairFactory } from '../hooks/usePairFactory'
import { useTransactionModal } from '../hooks/useTransactionModal'
import { ApproveNFTButton } from './ApproveButton'
import { ApproveERC20Button } from './ApproveERC20Button'
import { NFTImage } from './NFTImage'
import { tokens } from './PoolList'
import { RaveNameChip } from './RaveNameChip'

export const CreatePool: React.FC<{
  nftAddress?: string
  nftsIds?: string[]
}> = ({ nftAddress, nftsIds }) => {
  const {
    initialize,
    bondingCurve,
    erc20,
    nft,
    createPool,
    poolType,
    delta,
    spotPrice,
    initialIds,
    initialTokenBalance,
    fee,
    assetReceiver,
    setValue,
    address: pairFactoryAddress,
  } = usePairFactory()
  useTransactionModal()
  const { address } = useAccount()
  const { data: signer } = useSigner()
  useEffect(() => {
    signer && initialize(signer!)
  }, [signer, initialize])
  const getColor = (value: any, type?: string) => {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        return 'success'
      } else {
        return 'danger'
      }
    }

    if (typeof value === 'undefined') {
      return 'danger'
    }
    if (type === 'address') {
      return value.toLowerCase().match(/(\b0x[a-f0-9]{40}\b)/g) === null
        ? 'danger'
        : 'success'
    }
    return 'success'
  }

  const {
    ownedCollections,
    collections,
    inventory,
    fetchCollectionInfo,
    getFallbackImage,
    fetchAddressInventory,
  } = usePaintSwap()
  const { calculateCurveData, getCurveData } = useBondingCurve()
  const nftInventory = (nft && inventory[nft]) || []
  const { post: postTx } = useTransactionModal()
  const { post } = useNotification()
  useEffect(() => {
    nftAddress && setValue('nft', nftAddress)
  }, [nftAddress, setValue])
  useEffect(() => {
    address && nft && fetchCollectionInfo(address, [nft])
    address && nft && fetchAddressInventory(address, nft)
  }, [nft, address, fetchAddressInventory, fetchCollectionInfo])
  const curveData = getCurveData(
    bondingCurve || '',
    initialIds.length,
    fromFloat(delta! || 1),
    percentFromFloat(fee! || 0),
    fromFloat(spotPrice! || 1),
    fromFloat(initialTokenBalance! || 0),
    poolType,
  )
  useEffect(() => {
    bondingCurve &&
      delta &&
      signer &&
      spotPrice &&
      calculateCurveData(
        signer,
        bondingCurve,
        initialIds.length,
        fromFloat(delta! || 1),
        percentFromFloat(fee! || 0),
        fromFloat(spotPrice! || 1),
        fromFloat(initialTokenBalance! || 0),
        poolType,
      )
  }, [
    poolType,
    initialIds,
    bondingCurve,
    delta,
    signer,
    fee,
    spotPrice,
    calculateCurveData,
    initialTokenBalance,
  ])
  useEffect(() => {
    if (poolType === '0') {
      setValue('initialIds', [])
    }
    if (poolType === '1') {
      setValue('initialTokenBalance', 0)
    }
  }, [poolType, setValue])
  useEffect(() => {
    if (typeof assetReceiver === 'undefined') {
      setValue('assetReceiver', address)
    }
  }, [address, assetReceiver, setValue])
  return (
    <>
      {!signer && <>Connect to create Pool</>}
      {signer && (
        <IonList>
          <IonItem>
            <IonChip color={getColor(erc20)}>Token</IonChip>
            <IonSelect
              value={erc20}
              onIonChange={(e) => {
                setValue('erc20', e.detail.value)
              }}
            >
              {Object.keys(tokens).map((x) => {
                return <IonSelectOption value={x}>{tokens[x]}</IonSelectOption>
              })}
            </IonSelect>
          </IonItem>
          {!nftAddress && (
            <IonItem>
              <IonChip color={getColor(nft)}>Nft</IonChip>
              <IonSelect
                interface="action-sheet"
                onIonChange={(e) => {
                  setValue('nft', e.detail.value)
                }}
              >
                {ownedCollections.map((x) => {
                  const info = collections.find((c) => x.address === c.address)
                  return info ? (
                    <IonSelectOption value={x.address}>
                      <NFTImage
                        src={info.thumbnail || getFallbackImage(x.address, '1')}
                      />
                      {info.name}
                    </IonSelectOption>
                  ) : (
                    <></>
                  )
                })}
              </IonSelect>
            </IonItem>
          )}

          <IonItem>
            <IonChip color={getColor(bondingCurve)}>BondingCurve</IonChip>
            <IonSelect
              value={bondingCurve}
              onIonChange={(e) => {
                setValue('bondingCurve', e.detail.value)
              }}
            >
              <IonSelectOption
                value={'0xDD62964C856445496Baaf2C4B1EC224966415aC2'}
              >
                Linear
              </IonSelectOption>
              {/* <IonSelectOption
                value={'0x17fbb6a2ab799c49c7249c9ea84deddf3030495a'}
              >
                XYK
              </IonSelectOption> */}
              <IonSelectOption
                value={'0xB2D04Fe0a6cb72E85e087492d5f3E634a7B940e3'}
              >
                Exponential
              </IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonChip color={getColor(poolType)}>PoolType</IonChip>
            <IonSelect
              value={poolType}
              onIonChange={(e) => {
                setValue('poolType', e.detail.value)
              }}
            >
              <IonSelectOption value={'0'}>Token</IonSelectOption>
              <IonSelectOption value={'1'}>NFT</IonSelectOption>
              <IonSelectOption value={'2'}>Trade</IonSelectOption>
            </IonSelect>
            {poolType && (
              <IonChip>
                {poolType === '0' && 'Buy NFTs with Tokens'}
                {poolType === '1' && 'Sell NFTs for tokens'}
                {poolType === '2' && 'Deposit Tokens & NFTS to collect fees'}
              </IonChip>
            )}
          </IonItem>

          {poolType !== '2' && (
            <IonItem>
              <IonChip
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  const response = confirm(
                    'Only change this value if you know what you are doing!',
                  )
                  if (response) {
                    setValue('assetReceiver', '')
                  }
                }}
                color={getColor(assetReceiver, 'address')}
              >
                AssetReceiver
              </IonChip>
              {assetReceiver === address && (
                <RaveNameChip address={assetReceiver!} />
              )}
              {assetReceiver === '0xdead000000000000000000000000000000000000' ||
                (assetReceiver ===
                  '0x000000000000000000000000000000000000dead' && (
                  <IonChip>
                    <IonIcon icon={skullOutline} />
                    <IonText>Dead Wallet</IonText>
                  </IonChip>
                ))}
              {assetReceiver !== address && (
                <IonInput
                  value={assetReceiver}
                  onIonChange={(e) => {
                    setValue('assetReceiver', e.detail.value)
                  }}
                ></IonInput>
              )}
            </IonItem>
          )}

          <IonItem>
            <IonChip color={getColor(delta)}>Delta </IonChip>
            <IonInput
              min={
                bondingCurve === '0xB2D04Fe0a6cb72E85e087492d5f3E634a7B940e3'
                  ? '1'
                  : '0'
              }
              placeholder="## (Change in price after each Sale / Purchase)"
              onIonChange={(e) => {
                const rounded = e.detail.value
                setValue('delta', rounded)
              }}
              type="number"
            ></IonInput>
          </IonItem>
          {poolType === '2' && (
            <IonItem>
              <IonChip color={getColor(fee)}>Fee%</IonChip>
              <IonInput
                step="1"
                min="0"
                placeholder="% Percentage fee to take per trade"
                onIonChange={(e) => {
                  setValue('fee', e.detail.value)
                }}
                type="number"
              ></IonInput>
            </IonItem>
          )}
          {poolType !== '1' && (
            <IonItem>
              <IonChip color={getColor(initialTokenBalance)}>
                Initial Token Balance
              </IonChip>
              <IonInput
                step="1"
                min="0"
                placeholder="Initial Token amount in the pool (whole numbers only)"
                onIonChange={(e) => {
                  const rounded = e.detail.value
                  setValue('initialTokenBalance', rounded)
                }}
                type="number"
              ></IonInput>
              {initialTokenBalance}
            </IonItem>
          )}
          <IonItem>
            <IonChip color={getColor(spotPrice)}>Spot Price</IonChip>
            <IonInput
              step="1"
              min="1"
              placeholder="Initial Spot price "
              onIonChange={(e) => {
                setValue('spotPrice', e.detail.value)
              }}
              type="number"
            ></IonInput>
          </IonItem>
          {poolType !== '0' && (
            <IonItem>
              <IonChip color={getColor(initialIds)}>Initial NFTs</IonChip>
              {initialIds &&
                initialIds.map((id) => {
                  const nft = nftInventory.find((x) => x.tokenId === id)!
                  return (
                    <IonChip
                      onClick={() => {
                        setValue(
                          'initialIds',
                          initialIds?.filter((x) => x !== id),
                        )
                      }}
                    >
                      <IonAvatar>
                        <NFTImage
                          style={{ border: 5 }}
                          src={
                            nft.image ||
                            nft.thumbnail ||
                            getFallbackImage(nft.address, nft.tokenId)
                          }
                        />
                      </IonAvatar>
                      <IonTitle>{id}</IonTitle>
                    </IonChip>
                  )
                })}
            </IonItem>
          )}
          <IonItem>
            {poolType !== '0' &&
              nftInventory
                .filter((y) => !initialIds.includes(y.tokenId))
                .map((nft) => (
                  <NFTImage
                    style={{ border: 5, height: 250 }}
                    onClick={() => {
                      setValue(
                        'initialIds',
                        Array.from(new Set([...initialIds, nft.tokenId])),
                      )
                    }}
                    src={
                      nft.thumbnail ||
                      nft.image ||
                      getFallbackImage(nft.address, nft.tokenId)
                    }
                  />
                ))}
          </IonItem>
          {nft && poolType !== '0' && (
            <ApproveNFTButton
              nft={nft}
              operator={pairFactoryAddress}
            ></ApproveNFTButton>
          )}
          {erc20 &&
            erc20 !== 'fantom' &&
            spotPrice &&
            poolType !== '1' &&
            typeof poolType !== 'undefined' &&
            initialTokenBalance && (
              <ApproveERC20Button
                contract={erc20}
                amount={fromFloat(initialTokenBalance)}
                operator={pairFactoryAddress}
              ></ApproveERC20Button>
            )}

          {curveData && (
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart
                width={window.innerWidth / 2}
                height={window.innerHeight / 2}
                data={Object.entries(curveData).map(([index, info]) => info)}
                margin={{
                  top: 20,
                  right: 20,
                  left: 20,
                  bottom: 20,
                }}
              >
                <XAxis dataKey="items" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  stackId={'0'}
                  type="monotone"
                  dataKey="protocolFee"
                  fill="#5260ff"
                  stroke="#5260ff"
                />
                {poolType === '2' && (
                  <Area
                    stackId={'0'}
                    type="monotone"
                    dataKey="lpSellFee"
                    fill="darkRed"
                    stroke="darkRed"
                  />
                )}
                {poolType === '2' && (
                  <Area
                    stackId={'1'}
                    type="monotone"
                    dataKey="lpBuyFee"
                    fill="darkgreen"
                    stroke="darkgreen"
                  />
                )}

                <Area
                  stackId={'1'}
                  type="monotone"
                  dataKey="buyPrice"
                  fill="#2dd36f"
                  stroke="#2dd36f"
                />
                <Area
                  stackId={'0'}
                  type="monotone"
                  dataKey="sellPrice"
                  fill="#eb445a"
                  stroke="#eb445a"
                />
              </AreaChart>
            </ResponsiveContainer>
          )}

          {address && spotPrice && poolType && delta && (
            <IonButton
              expand="full"
              onClick={() => {
                const promise = createPool(address)
                promise &&
                  promise
                    .catch((message) => {
                      post({ color: 'danger', message: message.reason })
                    })
                    .then((tx) => {
                      postTx(tx, 'Creating Pool', () => {
                        window.location.href = 'https://potionswap.xyz/pools'
                      })
                    })
              }}
            >
              Create Pool
            </IonButton>
          )}
        </IonList>
      )}
    </>
  )
}

import { IonImg } from "@ionic/react";

const overrides:Record<string,string>={["https://smol.mypinata.cloud/ipfs/QmWRt1zoQAMHbE2G3jK1Nphp8x646DNH3FL6AyukL81KNn"]:"video"}
async function getMimeType(uri: string): Promise<string | undefined> {
    const fileExtension=getFileExtension(uri);
    if(typeof overrides[uri]!=='undefined'){
        return         new Promise<string>((resolve)=>{
            resolve(overrides[uri]);
        })
    }
    if(fileExtension in ['png','jpg','jpeg']){
        return         new Promise<string>((resolve)=>{
            resolve("image");
        })
    }
    const response = await fetch(uri,{mode:"no-cors"});
    const contentType = response.headers.get("Content-Type");
    if (contentType) {
        return contentType.split(";")[0];
    }

    // If the Content-Type header is not present in the response, return undefined
    return undefined;
}
export function getFileExtension(filename: string): string {
    // Find the last period (.) in the filename
    const lastDotIndex = filename.lastIndexOf(".");
    // If there is no period, return an empty string
    if (lastDotIndex === -1) {
      return "";
    }
  
    // Extract the file extension from the filename
    const extension = filename.slice(lastDotIndex+1,filename.length
         );
  
    return extension.toLocaleLowerCase();
  }
export const NFTImage: React.FC<{ src: string, style?: any, onClick?: any }> = ({ src, onClick, style }) => {
    // const [mime, setMime] = useState<string>()
    // useEffect(() => {
    //     getMimeType(src).then((res) => {
    //         setMime(res)
    //     }).catch(()=>{
    //         setMime("error")
    //     });
    // }, [src])
    //     if (mime==='error'){
    //     return <IonImg src="error"/>
    // }
    // if (mime?.includes('video')) {
    //     return <div onClick={onClick} style={style}><video width={300} height={300} style={{ objectFit: 'fill', ...style }} src={src} onClick={onClick} /></div>
    // }
    if(src.endsWith("mp4")){
        return <video autoPlay width={"100%"} src={src} onClick={onClick}/>
    }
    return <IonImg onError={()=>{
    }} onClick={onClick} style={{ ...style || {} }} src={src.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
} />
}
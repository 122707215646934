import { IonAvatar, IonChip, IonImg, IonText } from '@ionic/react'
import { useEffect, useState } from 'react'
import { Rave, RaveName } from '@rave-names/rave/lib/'
import { create } from 'zustand'
const rave = new Rave()
export type RaveCacheTemplate = {
  cache: Record<string, RaveName>,
  add: (address: string, name: RaveName) => void
}

export const useRaveCache = create<RaveCacheTemplate>((set, store) => ({
  cache: {}
  , add: (address, name) => {
    set({ cache: { ...store().cache, [address.toLowerCase()]: name } })
  }
}))
export const RaveNameChip: React.FC<{ address: string }> = ({ address }) => {
  const name = useRaveCache(x=>x.cache[address.toLowerCase()])
  const {add} = useRaveCache();
  useEffect(() => {
    if (typeof name!=='undefined'){
      return;
    }
    rave
      .reverse(address as any)
      .then((res) => {
        add(address,res)
      }).catch(()=>{
        const backup:RaveName={name:address,isOwned:false,addresses:[address],avatar:"https://avatars.githubusercontent.com/u/39045722?s=280&v=4"}
        add(address,backup);
      })
  }, [add,name, address])

  return (
    <IonChip>
      {typeof name === 'undefined' ? (
        address
      ) : (
        <>
          <IonAvatar>
            <IonImg  src={name.avatar||"https://avatars.githubusercontent.com/u/39045722?s=280&v=4"} alt="" />
          </IonAvatar>
          <IonText>{name.name}</IonText>
        </>
      )}
    </IonChip>
  )
}

import {
  IonApp,
  IonAvatar,
  IonCard,
  IonCardContent,
  IonChip,
  IonContent,
  IonItem,
  IonItemDivider,
  IonRouterOutlet,
  IonText,
  IonTitle,
  setupIonicReact
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import { fantom } from "wagmi/chains";
import Pools from "./pages/Pools";
import { Swaps } from "./pages/Swaps";
import "./theme/variables.css";

import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { Arbitrage } from "./components/Arbitrage";
import { Burn } from "./components/Burn";
import CollectionViewer from "./components/CollectionViewer";
import { MyPools } from "./components/MyPools";
import { PaintSwap } from "./components/PaintSwap";
import TokenViewer from "./components/TokenViewer";
import { WalletViewer } from "./components/WalletViewer";
import { Page } from "./pages/Page";
import Pool from "./pages/Pool";
import { PoolAdmin } from "./pages/PoolAdmin";
import { PoolCreator } from "./pages/PoolCreator";
import { PoolDirectory } from "./pages/PoolDirectory";
import Stats from "./pages/Stats";
import { TarotPage } from "./pages/Tarot";

import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc';
import { PoolGraph } from "./components/PoolGraph";
import { PoolList } from "./components/PoolList";


const { provider, webSocketProvider } = configureChains(
  [fantom],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: 'https://rpc.ftm.tools',
      }),
    }),
  ],);


const client = createClient({
  provider,
  webSocketProvider,

});
setupIonicReact();

export const graphQLclient = new ApolloClient({
  uri: "https://api.studio.thegraph.com/query/44847/potionswap/version/latest",
  cache: new InMemoryCache(),
});

const App: React.FC = () => (
  <IonApp>
    <ApolloProvider client={graphQLclient}>
      <WagmiConfig client={client}>
        <PaintSwap />
        <IonReactRouter>
          <Page>
            <IonRouterOutlet id="main">
              <Route path="/history" exact={true}>
                <Swaps />
              </Route>
              <Route path="/arbitrage" exact={true}>
                <Arbitrage />
              </Route>
              <Route path="/trade" exact={true}>
                <iframe
                  title="Swap"
                  style={{
                    width: "100%",
                    height: window.innerHeight,
                    border: "none",
                  }}
                  src={
                    "https://app.bogged.finance/ftm/swap?chain=fantom&tokenIn=FTM&tokenOut=0x3edA36088b931098e8E472748840b3dF78268c72&embed=1&theme=dark"
                  }
                />
              </Route>
              <Route path="/info" exact={true}>
                <IonContent>
                  <IonTitle color="tertiary">PotionSwap</IonTitle>
                  <IonCard>
                    <IonCardContent>
                      <IonText>
                        Potionswap is a fork of sudoswap. check out their
                        <a href="https://docs.sudoswap.xyz/">
                          documentation
                        </a>{" "}
                        to learn more about how it works, I didn't change the
                        contracts at all!
                      </IonText>
                    </IonCardContent>
                    <IonItem>
                      <IonChip color="success">
                        Potionswap protocol Fee 1%
                      </IonChip>
                    </IonItem>

                    <IonItem>
                      <a href="https://ftmscan.com/address/0x90069a0a7135108f032dbb9a0ddfb42f4c479589">
                        Potionswap NFT Pair Factory Address
                      </a>
                    </IonItem>
                    <IonItem>
                      <a href="https://ftmscan.com/address/0xF35b6c95d5dc634B1409df388d28278C3B3c6c23">
                        Potionswap NFT Router Address
                      </a>
                    </IonItem>
                    {/* <IonItem>
                    <a href="https://ftmscan.com/address/0x67b3bf12bad1cb24e85b5bae0ecae198d9a1c843">
                      Uniswapv2 Factory Address
                    </a>
                  </IonItem>
                  <IonItem>
                    <a href="https://ftmscan.com/address/0x33851dc77c47b4b1f7f83b49625aaa3520006073">
                      Uniswapv2 Router Address
                    </a>
                  </IonItem> */}
                    <IonItemDivider />
                    <IonItem
                      target="_blank"
                      href="https://twitter.com/lil_esper"
                    >
                      <IonChip>
                        <IonAvatar>
                          <img
                            alt="lil esper"
                            src="https://pbs.twimg.com/profile_images/1807400752769339393/yfHQn91Z_400x400.png"
                          />
                        </IonAvatar>{" "}
                        <IonText>made by lil Esper</IonText>
                      </IonChip>
                    </IonItem>
                  </IonCard>
                </IonContent>
              </Route>
              <Route path="/pools" exact={true}>
                <PoolList />
              </Route>
              <Route path="/burn/" exact={true}>
                <Burn />
              </Route>
              <Route path="/pool/:address" exact={true}>
                <Pool />
              </Route>
              <Route path="/create/:address" exact={true}>
                <PoolCreator />
              </Route>
              <Route path="/pool-info" exact={true}>
                <PoolDirectory />
              </Route>
              <Route path="/admin/:address" exact={true}>
                <PoolAdmin />
              </Route>
              <Route path="/my-pools/" exact={true}>
                <MyPools />
              </Route>
              <Redirect path="/" to={"pools"} exact={true} />
              <Route path="/wallet" exact={true}>
                <WalletViewer />
              </Route>
              {/* <Route path="/stats" exact={true}>
                <Stats />
              </Route>
              <Route path="/tarot" exact={true}>
                <TarotPage />
              </Route> */}
              <Route path="/token/:address/:id" exact={true}>
                <TokenViewer />
              </Route>
              <Route path="/collection/:address/" exact={true}>
                <CollectionViewer />
              </Route>
            </IonRouterOutlet>
          </Page>
        </IonReactRouter>
      </WagmiConfig>
    </ApolloProvider>
  </IonApp>
);

export default App;

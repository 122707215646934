import { IonButton, IonContent } from "@ionic/react";
import { useEffect } from "react";
import { usePairRouter } from "../hooks/usePairRouter";
import { useAccount, useSigner } from "wagmi";
import { BigNumber } from "ethers";
import { ApproveNFTButton } from "./ApproveButton";

export const Arbitrage:React.FC=()=>{
    const {router,initialize} = usePairRouter();
    const {address:account}=useAccount();
    const {data:signer} = useSigner();
    useEffect(()=>{
        account&&signer &&initialize(signer);
},[account, initialize, signer])
return <IonContent>
   {router&& <ApproveNFTButton nft="0xf9e393cbd7e8f34fb87127195f1f74e699d3d595" operator={router.address}/>}
<IonButton onClick={()=>{
            router&& account&&signer&&router.robustSwapETHForSpecificNFTsAndNFTsToToken({inputAmount:1,nftRecipient:account,nftToTokenTrades:[{minOutput:1,swapInfo:{nftIds:[BigNumber.from(4518)],pair:"0x7d5af9bc1ce78b4d57074e53e7ae7227f24c54e6"}}],tokenRecipient:account,tokenToNFTTrades:[{maxCost:BigNumber.from("3570000000000000000"),swapInfo:{nftIds:[BigNumber.from(4518)],pair:"0xd67d34aa547cb0cb42c41587a4fc38d7b4ed36d3"}}]})
}}>Arbitrage
</IonButton>
</IonContent>}
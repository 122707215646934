import { IonAvatar, IonButton, IonChip, IonImg, IonText } from '@ionic/react'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { useEffect, useState } from 'react'
import { useAccount, useBalance, useConnect, useSigner } from 'wagmi'
import { fantom } from 'wagmi/chains'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { useERC20 } from '../hooks/useERC20'
import { formatTokenPrice } from './PoolList'
import { Rave, RaveName } from '@rave-names/rave/lib'
const rave = new Rave()
export const potionAddress="0x3edA36088b931098e8E472748840b3dF78268c72";
const ConnectButton: React.FC = () => {
  const img =
    'https://cloudflare-ipfs.com/ipfs/QmTWzBGYm8HrmiVRwcxarQ3yzUaXD3vHw5XcypZxYUq86S'
  const {
    connect,
    connectors,
    error,
    isLoading,
    isSuccess,
    pendingConnector,
    status,
  } = useConnect()
  const { address } = useAccount()
  const { data, refetch } = useBalance({ address })
  const { data: signer } = useSigner()
  const { initialize, instances } = useERC20()
  const potion = instances[potionAddress]
  const [potionBalance, setPotionBalance] = useState<BigNumber>(
    BigNumber.from(0),
  )
  const [name, setName] = useState<RaveName>()
  useEffect(() => {
    const name =
      address &&
      rave.reverse(address as any).then((res) => {
        setName(res)
      })
  }, [address])
  useEffect(() => {
    signer && initialize('0x3edA36088b931098e8E472748840b3dF78268c72', signer)
  }, [signer])
  useEffect(() => {
    address &&
      potion &&
      potion.balanceOf(address).then((res) => {
        setPotionBalance(res)
      })
  }, [address, potion])

  if (isSuccess || address) {
    return (
      <>
        <IonChip color="tertiary">
          <IonAvatar>
            <IonImg
              src={'https://magepunks.xyz/icon.png'}
            ></IonImg>
          </IonAvatar>
          <IonText>{formatTokenPrice(potionBalance)}</IonText>
        </IonChip>

        <IonText color="success" onClick={()=>{
        }}>{data?.formatted.split('.')[0]} FTM</IonText>
        <IonChip color="success">
          {name && name.avatar && (
            <IonAvatar>
              <IonImg src={name.avatar} />
            </IonAvatar>
          )}
          <IonText>
            {typeof address !== 'undefined' && (
              <>
                {name
                  ? name.name
                  : address.slice(0, 6) + '...' + address.slice(38, 55)}
              </>
            )}
          </IonText>
        </IonChip>
      </>
    )
  }
  const connector = new InjectedConnector({ chains: [fantom] })
  return (
    <IonButton
      fill="solid"
      color="primary"
      onClick={() => {
        connect({ chainId: 250, connector })
      }}
    >
      Connect
    </IonButton>
  )
}

export default ConnectButton

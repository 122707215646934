import { fetchBalance } from '@wagmi/core'
import { BigNumber, Signer, ethers } from 'ethers'
import { create } from 'zustand'
import { Erc20__factory } from '../types/ethers-contracts/factories/Erc20__factory'
import { PairERC20__factory } from '../types/ethers-contracts/factories/PairERC20__factory'
import { Pair__factory } from '../types/ethers-contracts/factories/Pair__factory'
import { Pair } from '../types/ethers-contracts/Pair'
import { PairERC20 } from '../types/ethers-contracts/PairERC20'

export type PairInfo = [number, BigNumber, BigNumber, BigNumber, BigNumber]
export type PairTemplate = {
  instance: Record<string, Pair | PairERC20>
  sellQuote: Record<string, PairInfo>
  buyQuote: Record<string, PairInfo>
  fee: Record<string, BigNumber>
  spotPrice: Record<string, BigNumber>
  info:Record<string,{owner:string,collection:string,erc20:string,bondingCurve:string,poolType:string}>
  delta: Record<string, BigNumber>
  tokenBalance: Record<string, BigNumber>
  heldIds: Record<string, BigNumber[]>
  load:(data:Array<{assetRecipient:string,bondingCurveAddress:string,erc20Contract:string,id:string,nft:string,owner:string,poolType:string}>)=>void,
  getSellPrice: (address: string) => BigNumber | undefined
  getBuyPrice: (address: string) => BigNumber | undefined
  getInfo: (address: string) => {owner:string,collection:string,erc20:string,bondingCurve:string,poolType:string} 
  initialize: (address: string, signer: Signer|ethers.providers.Provider) => void
}

export const usePair = create<PairTemplate>((set, store) => ({
  instance: {},
  buyQuote: {},
  sellQuote: {},
  info:{},
  delta: {},
  fee: {},
  spotPrice: {},
  tokenBalance: {},
  heldIds: {},
  getBuyPrice: (address) => {
    return store().buyQuote[address] && store().buyQuote[address][3]
  },
  getSellPrice: (address) => {
    return store().sellQuote[address] && store().sellQuote[address][3]
  },
  getInfo: (address) => {
    return store().info[address]||{assetRecipient:"",bondingCurveAddress:"",erc20Contract:"",id:"",nft:"",owner:"",poolType:""}
  },
  load:(data)=>{
    const info=data.map(({assetRecipient,bondingCurveAddress,erc20Contract,id,nft,owner,poolType})=>{
      return {[id]:{assetRecipient,bondingCurve:bondingCurveAddress,erc20:erc20Contract,id,collection:nft,owner,poolType}}      
    }).reduce((a,b)=>({...a,...b}),{})
    set({info});
  },
  initialize: async (a, signer) => {
    const address = a.toLowerCase()
    const instance = Pair__factory.connect(address, signer)
    const erc20Instance = PairERC20__factory.connect(address, signer)
    instance.getBuyNFTQuote(1).then((itemInfo) => {
      set({
        buyQuote: {
          ...store().buyQuote,
          [address]: itemInfo,
        },
      })
    })

    instance.fee().then((feeInfo) => {
      set({
        fee: {
          ...store().fee,
          [address]: feeInfo,
        },
      })
    })

    instance.delta().then((deltaAmount) => {
      set({
        delta: {
          ...store().delta,
          [address]: deltaAmount,
        },
      })
    })

    instance.spotPrice().then((spotPrice) => {
      set({
        spotPrice: {
          ...store().spotPrice,
          [address]: spotPrice,
        },
      })
    })
    instance.getSellNFTQuote(1).then((itemInfo) => {
      set({
        sellQuote: {
          ...store().sellQuote,
          [address]: itemInfo,
        },
      })
    })
    instance.getAllHeldIds().then((heldIds) => {
      set({
        heldIds: {
          ...store().heldIds,
          [address]: heldIds,
        },
      })
    })
    
    let tokenAddress=store().getInfo(address).erc20;
    console.log("token Address");
    if(typeof tokenAddress==='undefined'){
      try{
        console.log("TESTING INSTANCE DIRECTLY");
        tokenAddress = await erc20Instance.token()
      }catch{
        tokenAddress = 'fantom'
      }

    }
    if(tokenAddress!=='fantom'){
      console.log(tokenAddress);
      Erc20__factory.connect(tokenAddress, signer)
      .balanceOf(address)
      .then((erc20Balance) => {
        set({
          instance:{
            ...store().instance,
            [address]:erc20Instance
                        },            
          tokenBalance: {
            ...store().tokenBalance,
            [address]: erc20Balance,
          },
        })
      })
    
    }else{
      fetchBalance({ address: address as any, chainId: 250 }).then(
        (ftmBalance) => {
          set({
            instance:{
...store().instance,
[address]:instance
            },
            tokenBalance: {
              ...store().tokenBalance,
              [address]: ftmBalance.value,              
            },
          })
        },
      )
    }
    

  },
}))

import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonChip,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonProgressBar,
  IonRow,
  IonSkeletonText,
  IonSpinner,
  IonText,
  IonTitle,
} from "@ionic/react";
import { BigNumber, ethers } from "ethers";
import { formatEther } from "ethers/lib/utils.js";
import {
  arrowBack,
  arrowForward,
  closeOutline,
  settingsOutline,
  shieldCheckmarkSharp,
  swapHorizontalOutline,
  walletOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAccount, useBalance, useSigner } from "wagmi";
import { useERC20 } from "../hooks/useERC20";
import { useERC721 } from "../hooks/useERC721";
import { useNotification } from "../hooks/useNotifications";
import { usePaintSwap } from "../hooks/usePaintSwap";
import { usePairFactory } from "../hooks/usePairFactory";
import { usePairRouter } from "../hooks/usePairRouter";
import { usePair } from "../hooks/usePairs";
import { useTransactionModal } from "../hooks/useTransactionModal";
import { ApproveNFTButton } from "./ApproveButton";
import { ApproveERC20Button } from "./ApproveERC20Button";
import ConnectButton from "./ConnectButton";
import { NFTImage } from "./NFTImage";

import "@ionic/react/css/ionic-swiper.css";
import "swiper/swiper.min.css";

export const tokens: Record<string, string> = {
  fantom: "$ftm",
  ["0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83".toLowerCase()]: "$wftm",
  ["0x3edA36088b931098e8E472748840b3dF78268c72".toLowerCase()]: "$potion",
  ["0xe47d957f83f8887063150aaf7187411351643392".toLowerCase()]: "$chill",
  ["0x454d4BaE7c2adab588d837aFF4Db16Db19d46A33".toLowerCase()]: "$gmFTM",
  ["0x208234F4f8B1bBEA59cfDc38666141654e851DCe".toLowerCase()]: "$candle",
  ["0x5d9EaFC54567F34164A269Ba6C099068df6ef651".toLowerCase()]: "$surv",
  ["0xE388D77715f90Bcf4711EbDB6733A86A7ad79C36".toLowerCase()]: "$love",
  ["0x479673391b3818f5e3ED2fa69A58e13d685BEcf6".toLowerCase()]: "$thc",
  ["0xE5a4c0af6F5f7Ab5d6C1D38254bCf4Cc26d688ed".toLowerCase()]: "$oldbay",
  ["0xd361474bb19c8b98870bb67f5759cdf277dee7f9".toLowerCase()]: "$bay",
  ["0x56644fd4f72cd8698f3981d10a29010c81766283".toLowerCase()]: "$cob",
};
export const icons: Record<string, string> = {
  $wftm: "https://ftmscan.com/token/images/wFtm_32.png",
  $ftm: "https://avatars.githubusercontent.com/u/39045722?s=280&v=4",
  $surv:
    "https://raw.githubusercontent.com/SoulSwapFinance/assets/master/blockchains/fantom/assets/0x5d9EaFC54567F34164A269Ba6C099068df6ef651/logo.png",
  $potion: "https://magepunks.cc/assets/images/potion-icon.png",
  $chill: "/assets/icon/pill.png",
  $love: "https://raw.githubusercontent.com/mageswap/default-token-list/main/build/love-ftm-icon.png",
  $thc: "https://dd.dexscreener.com/ds-data/tokens/fantom/0x479673391b3818f5e3ed2fa69a58e13d685becf6.png?size=lg",
  $gmFTM:
    "https://pbs.twimg.com/profile_images/1626763112794796032/BkRZKJyA_400x400.jpg",
    $bay: "https://s2.coinmarketcap.com/static/img/coins/64x64/25431.png",
    $oldbay: "https://i5.walmartimages.com/seo/OLD-BAY-Classic-Seafood-Seasoning-6-oz-Mixed-Spices-Seasonings_80847a23-31c2-4b79-999c-2da3d9a51b42.b5f63b5bb3c3030036ba6096da18104f.jpeg?odnHeight=640&odnWidth=640&odnBg=FFFFFF",
};

export const formatTokenPrice = (amount: BigNumber) => {
  if (typeof amount === "undefined") {
    return "0.0";
  }
  if (amount.gte(BigNumber.from(10).pow(27))) {
    const inBillions = amount.div(BigNumber.from(10).pow(9));
    const inBillionsSplit = formatEther(inBillions).split(".");
    return inBillionsSplit[0] + "." + inBillionsSplit[1].substring(0, 2) + "B";
  } else {
    const priceString = formatEther(amount);
    const index = priceString.indexOf(".");
    const toPrecision2 = priceString.substring(0, index + 3);
    return toPrecision2;
  }
};

export const PoolInfo: React.FC<{ pair: string; pageSize?: number }> = ({
  pair,
}) => {
  const { data: signer } = useSigner();
  const { initialize } = usePair();
  const {
    router,
    initialize: initRouter,
    address: routerAddress,
  } = usePairRouter();
  const sm = window.innerWidth < 576;
  const pairA = pair.toLowerCase();
  const buyPrice = usePair((x) => x.getBuyPrice(pairA));
  const sellPrice = usePair((x) => x.getSellPrice(pairA));
  const heldIds = usePair((x) => x.heldIds[pairA]);
  const { collection, erc20: token, owner, poolType } = usePair((x) => x.getInfo(pairA));
  const { address: walletAddress } = useAccount();
  const [nftIdsSell, setNftIdsSell] = useState<string[]>([]);
  const [nftIdsBuy, setNftIdsBuy] = useState<BigNumber[]>([]);
  const [nftIdsPurchased, setNftIdsPurchased] = useState<BigNumber[]>([]);
  const [nftIdsSold, setNftIdsSold] = useState<BigNumber[]>([]);
  const connectERC20 = useERC20((x) => x.initialize);
  const { post } = useNotification();
  const { post: postTx } = useTransactionModal();
  const deadline = parseInt((new Date().getTime() / 1000).toFixed(0)) + 4200;
  const { initialize: connectErc721 } = useERC721();
  const { data: ftmBalance } = useBalance({ address: pair as any });
  const { instances } = useERC20();
  const tokenInstance = instances[token];
  const [pairTokenBalance, setPairTokenBalance] = useState<BigNumber>();
  useEffect(() => {
    const fantomProvider = new ethers.providers.JsonRpcProvider(
      "https://rpc.ftm.tools/"
    );
    initialize(pairA, signer ? signer : fantomProvider);
    initRouter(signer ? signer : fantomProvider);
  }, [signer, initRouter, initialize, pairA]);
  useEffect(() => {
    const fantomProvider = new ethers.providers.JsonRpcProvider(
      "https://rpc.ftm.tools/"
    );
    collection && connectErc721(collection, signer ? signer : fantomProvider);
  }, [signer, collection, connectErc721]);

  useEffect(() => {
    const fantomProvider = new ethers.providers.JsonRpcProvider(
      "https://rpc.ftm.tools/"
    );
    token &&
      token !== "fantom" &&
      connectERC20(token, signer ? signer : fantomProvider);
    if (token === "fantom") {
      ftmBalance && setPairTokenBalance(ftmBalance?.value);
    }
  }, [signer, token, connectERC20, ftmBalance]);
  useEffect(() => {
    tokenInstance &&
      tokenInstance.balanceOf(pairA).then((response) => {
        setPairTokenBalance(response);
      });
  }, [tokenInstance, pairA]);

  const {
    inventory,
    fetchCollectionInfo,
    collections,
    getFallbackImage,
    fetchAddressInventory,
  } = usePaintSwap();
  const nftInventory = inventory[collection];
  const pairInventory = inventory[pairA + "-" + collection] || [];
  const currency = (token && tokens[token.toLowerCase()]) || "?";
  useEffect(() => {
    if (typeof nftInventory === "undefined" && collection && walletAddress) {
      fetchCollectionInfo(walletAddress, [collection]);
    }
  }, [walletAddress, collection, nftInventory, fetchCollectionInfo]);
  useEffect(() => {
    if (typeof nftInventory === "undefined" && collection && walletAddress) {
      fetchAddressInventory(pair, collection);
    }
  }, [
    pair,
    walletAddress,
    collection,
    nftInventory,
    fetchAddressInventory,
    fetchCollectionInfo,
  ]);
  const imgSize =
    window.innerWidth < 576
      ? "75px"
      : window.innerWidth > 768
        ? "150px"
        : "100px";

  const info = collections.find(
    (x) =>
      (x || { address: "" }).address.toLowerCase() ===
      (collection || "").toLowerCase()
  );
  const isOwner = walletAddress?.toLowerCase() === owner.toLowerCase();
  if (
    typeof heldIds === "undefined" ||
    typeof pairTokenBalance === "undefined"
  ) {
    return (
      <IonCol sizeSm="12" sizeMd="6">
        <IonCard>
          <IonCardTitle>
            <IonItem color={"light"}>
              <IonButtons slot="start">
                {info && (
                  <IonButton
                    routerLink={"/pool/" + pair}
                    color="clear"
                    expand="full"
                  >
                    <IonText color="medium">
                      {heldIds?.length}
                      {"x"}
                    </IonText>
                    {info.verified && (
                      <IonIcon color="primary" icon={shieldCheckmarkSharp} />
                    )}
                    <IonText color="primary">{info.name} </IonText>
                    <IonIcon
                      color="dark"
                      icon={
                        poolType === '2'
                          ? swapHorizontalOutline
                          : poolType === '1'
                            ? arrowForward
                            : arrowBack
                      }
                    />{" "}
                    <IonItem color={"light"}>
                      <IonChip color="tertiary">
                        <IonAvatar>
                          <IonImg src={icons[currency]} />
                        </IonAvatar>
                        <IonText color="tertiary">
                          {pairTokenBalance &&
                            pairTokenBalance.gt(0) &&
                            formatTokenPrice(pairTokenBalance)}
                          {currency}{" "}
                        </IonText>
                      </IonChip>
                      <IonButtons slot="end">
                        {info.stats && info.stats.floor && (
                          <IonBadge color="light">
                            <IonText color="medium">
                              PS Floor:
                              {formatTokenPrice(
                                BigNumber.from(info.stats.floor)
                              )}
                              FTM
                            </IonText>
                          </IonBadge>
                        )}
                      </IonButtons>
                    </IonItem>
                  </IonButton>
                )}
              </IonButtons>
              {heldIds && (
                <IonButtons slot="end">
                  {isOwner && (
                    <IonButton
                      color="danger"
                      routerLink={"/admin/" + pair}
                      routerDirection="none"
                    >
                      <IonIcon icon={settingsOutline} />
                    </IonButton>
                  )}
                </IonButtons>
              )}
            </IonItem>
          </IonCardTitle>

          <IonCardContent>
            <IonItem />
            <IonItem />
            <IonProgressBar type="indeterminate" />
            <IonItem />
            <IonItem />
            <IonSkeletonText />
          </IonCardContent>
        </IonCard>
      </IonCol>
    );
  }
  if (heldIds.length === 0 && pairTokenBalance?.eq(0)) {
    return <></>;
  }
  return (
    <IonCol sizeSm="12" sizeMd="6">
      <IonCard>
        <IonCardTitle>
          <IonItem color={"light"}>
            <IonButtons slot="start">
              {info && (
                <IonButton
                  routerLink={"/pool/" + pair}
                  color="clear"
                  expand="full"
                >
                  <IonText color="medium">
                    {heldIds?.length}
                    {"x"}
                  </IonText>
                  {info.verified && (
                    <IonIcon color="primary" icon={shieldCheckmarkSharp} />
                  )}
                  <IonText color="primary">{info.name} </IonText>
                  <IonIcon
                    color="dark"
                    icon={
                      poolType === '2'
                        ? swapHorizontalOutline
                        : poolType === '1'
                          ? arrowForward
                          : arrowBack
                    }
                  />{" "}
                  <IonItem color={"light"}>
                    <IonChip color="tertiary">
                      <IonAvatar>
                        <IonImg src={icons[currency]} />
                      </IonAvatar>
                      <IonText color="tertiary">
                        {pairTokenBalance &&
                          pairTokenBalance.gt(0) &&
                          formatTokenPrice(pairTokenBalance)}
                        {currency}{" "}
                      </IonText>
                    </IonChip>
                    <IonButtons slot="end">
                      {info.stats && info.stats.floor && (
                        <IonBadge color="light">
                          <IonText color="medium">
                            PS Floor:
                            {formatTokenPrice(BigNumber.from(info.stats.floor))}
                            FTM
                          </IonText>
                        </IonBadge>
                      )}
                    </IonButtons>
                  </IonItem>
                </IonButton>
              )}
            </IonButtons>
            {heldIds && (
              <IonButtons slot="end">
                {isOwner && (
                  <IonButton
                    color="danger"
                    routerLink={"/admin/" + pair}
                    routerDirection="none"
                  >
                    <IonIcon icon={settingsOutline} />
                  </IonButton>
                )}
              </IonButtons>
            )}
          </IonItem>
        </IonCardTitle>
        <IonCardContent>
          {heldIds && (
            <IonList>
              <IonItem>
                <Swiper
                  slidesPerView={
                    heldIds.length > 5 ? 5 : heldIds.length - nftIdsBuy.length
                  }
                >
                  {heldIds &&
                    heldIds
                      .filter((x) => !nftIdsBuy.includes(x))
                      .filter((x) => !nftIdsPurchased.includes(x))
                      .map((id) => {
                        const nft = pairInventory.find(
                          (x) => x.tokenId === id.toNumber().toString()
                        );
                        return (
                          <SwiperSlide key={id.toString()}>
                            <div
                              style={{
                                borderRadius: "10px",
                                minHeight: imgSize,
                                minWidth: imgSize,
                                maxHeight: imgSize,
                                maxWidth: imgSize,
                              }}
                              key={id.toString()}
                              onClick={() => {
                                // if (nftIdsBuy.includes(id)) {
                                //   setNftIdsBuy(nftIdsBuy.filter((x) => x !== id))
                                // } else {
                                //   setNftIdsBuy(
                                //     Array.from(
                                //       new Set<BigNumber>([...nftIdsBuy, id]),
                                //     ),
                                //   )
                                // }
                                setNftIdsBuy([BigNumber.from(id)]);
                                setNftIdsSell([]);
                              }}
                            >
                              <NFTImage
                                src={
                                  nft?.image ||
                                  nft?.thumbnail ||
                                  getFallbackImage(
                                    collection,
                                    id.toNumber().toString()
                                  )
                                }
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                </Swiper>
              </IonItem>
              {nftIdsBuy.length > 0 && (
                <>
                  {nftIdsBuy &&
                    nftIdsBuy.map((id) => {
                      const nft = pairInventory.find((x) =>
                        id.eq(BigNumber.from(x.tokenId))
                      );
                      return (
                        <div
                          style={{
                            border: "2px solid #5bef96",
                            borderRadius: 3,
                          }}
                          key={id.toString()}
                        >
                          <IonCardTitle>
                            <IonItem>
                              <IonTitle color="success">
                                {info?.name} {nftIdsBuy.map((x) => "#" + x)}
                              </IonTitle>
                              <IonButtons slot="end">
                                <IonButton
                                  onClick={() => {
                                    setNftIdsBuy(
                                      nftIdsBuy.filter((x) => x !== id)
                                    );
                                  }}
                                >
                                  <IonIcon color="danger" icon={closeOutline} />
                                </IonButton>
                              </IonButtons>
                            </IonItem>
                          </IonCardTitle>
                          <IonGrid>
                            <IonRow>
                              <IonCol size="6">
                                <NFTImage
                                  onClick={() => {
                                    setNftIdsBuy(
                                      nftIdsBuy.filter((x) => x !== id)
                                    );
                                  }}
                                  style={{ height: 200 }}
                                  src={
                                    nft?.image ||
                                    getFallbackImage(collection, id.toString())
                                  }
                                />
                              </IonCol>
                              <IonCol size="6">
                                <IonCardContent>
                                  <IonTitle color="tertiary">
                                    {nft?.name}
                                  </IonTitle>
                                  <IonGrid>
                                    {nft?.attributes &&
                                      nft?.attributes
                                        .filter(
                                          (x) =>
                                            x.trait_type !== "Attributes Count"
                                        )
                                        .map((a) => (
                                          <IonChip>
                                            <IonBadge color="light">
                                              {a.trait_type}
                                            </IonBadge>
                                            {a.value}
                                          </IonChip>
                                        ))}
                                  </IonGrid>
                                </IonCardContent>
                              </IonCol>
                            </IonRow>
                            {!walletAddress && buyPrice && (
                              <IonText color="success">
                                {formatTokenPrice(buyPrice)}
                              </IonText>
                            )}
                            {walletAddress &&
                              buyPrice &&
                              nftIdsBuy.length !== 0 && (
                                <IonButton
                                  size="large"
                                  fill="clear"
                                  expand="full"
                                  color={"success"}
                                  onClick={() => {
                                    if (currency === "$ftm") {
                                      router
                                        ?.swapETHForSpecificNFTs(
                                          [
                                            {
                                              nftIds: nftIdsBuy.map(
                                                BigNumber.from
                                              ),
                                              pair,
                                            },
                                          ],
                                          walletAddress,
                                          walletAddress,
                                          deadline,
                                          { value: buyPrice }
                                        )
                                        .catch((message) => {
                                          post({
                                            color: "danger",
                                            message: message.reason,
                                          });
                                        })
                                        .then((tx) => {
                                          postTx(
                                            tx,
                                            "Purchasing nft from the pool",
                                            () => {
                                              setNftIdsPurchased(nftIdsBuy);
                                              setNftIdsBuy([]);
                                            }
                                          );
                                        });
                                    } else {
                                      router
                                        ?.swapERC20ForSpecificNFTs(
                                          [
                                            {
                                              nftIds: nftIdsBuy.map(
                                                BigNumber.from
                                              ),
                                              pair,
                                            },
                                          ],
                                          walletAddress,
                                          walletAddress,
                                          deadline
                                        )
                                        .catch((message) => {
                                          post({
                                            color: "danger",
                                            message: message.reason,
                                          });
                                        })
                                        .then((tx) => {
                                          postTx(
                                            tx,
                                            "Purchasing with " + currency,
                                            () => {
                                              setNftIdsPurchased(nftIdsBuy);
                                              setNftIdsBuy([]);
                                            }
                                          );
                                        });
                                    }
                                  }}
                                >
                                  {!sm && "Buy "}
                                  {nftIdsBuy.map(
                                    (x) => "#" + x.toString()
                                  )}{" "}
                                  {!sm && "for"}
                                  <IonChip color={"tertiary"}>
                                    {formatTokenPrice(
                                      buyPrice.mul(nftIdsBuy.length)
                                    )}{" "}
                                    <IonAvatar>
                                      <IonImg src={icons[currency]} />
                                    </IonAvatar>
                                  </IonChip>
                                </IonButton>
                              )}
                          </IonGrid>
                        </div>
                      );
                    })}
                </>
              )}
              {nftIdsSell.length > 0 && (
                <div
                  style={{
                    border: "2px solid #eb445a",
                    borderRadius: 3,
                  }}
                >
                  <IonCard>
                    <IonCardTitle>
                      <IonTitle color="danger">
                        {info?.name} {nftIdsSell.map((x) => "#" + x)}
                      </IonTitle>
                    </IonCardTitle>
                    {nftIdsSell &&
                      nftIdsSell.map((id) => {
                        const nft = nftInventory.find(
                          (nft) => nft.tokenId === id
                        )!;
                        return (
                          <IonGrid key={id}>
                            <IonRow>
                              <IonCol>
                                <NFTImage
                                  onClick={() => {
                                    setNftIdsSell([]);
                                  }}
                                  style={{ height: 200 }}
                                  src={
                                    nft?.image ||
                                    getFallbackImage(collection, id.toString())
                                  }
                                />
                              </IonCol>
                              <IonCol>
                                <IonCardContent>
                                  <IonTitle color="tertiary">
                                    {nft?.name}
                                  </IonTitle>
                                  {nft &&
                                    nft.attributes &&
                                    nft?.attributes
                                      .filter(
                                        (x) =>
                                          x.trait_type !== "Attributes Count"
                                      )
                                      .map((a) => (
                                        <IonChip>
                                          <IonBadge color="light">
                                            {a.trait_type}
                                          </IonBadge>
                                          {a.value}
                                        </IonChip>
                                      ))}
                                </IonCardContent>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        );
                      })}
                  </IonCard>
                  {pairTokenBalance &&
                    walletAddress &&
                    sellPrice &&
                    nftIdsSell.length > 0 ? (
                    <IonButton
                      size="large"
                      expand="full"
                      color={"light"}
                      onClick={() => {
                        if (sellPrice && pairTokenBalance?.lte(sellPrice)) {
                          post({
                            color: "medium",
                            message: "Pool is out of funds to buy",
                          });
                          return;
                        }
                        router
                          ?.robustSwapNFTsForToken(
                            [
                              {
                                minOutput: nftIdsSell.length,
                                swapInfo: {
                                  nftIds: nftIdsSell.map(BigNumber.from),
                                  pair,
                                },
                              },
                            ],
                            walletAddress,
                            deadline
                          )
                          .catch((message) => {
                            post({
                              color: "danger",
                              message: message.reason.replaceAll("ETH", "FTM"),
                            });
                          })
                          .then((tx) => {
                            postTx(tx, "Selling nft into pool", () => {
                              setNftIdsSold(nftIdsSell.map(BigNumber.from));
                              setNftIdsSell([]);
                            });
                          });
                      }}
                    >
                      <IonText color="danger">
                        {sellPrice &&
                          pairTokenBalance?.gte(sellPrice) &&
                          "Sell #" + nftIdsSell.map((x) => x)}
                      </IonText>
                      <IonChip
                        color={
                          sellPrice && pairTokenBalance?.gte(sellPrice)
                            ? "danger"
                            : "medium"
                        }
                      >
                        <IonAvatar>
                          <IonImg src={icons[currency]} />
                        </IonAvatar>
                        <IonText>
                          {formatTokenPrice(sellPrice.mul(nftIdsSell.length))}{" "}
                        </IonText>
                      </IonChip>
                    </IonButton>
                  ) : poolType !== '1' ? (
                    <IonChip
                      color={
                        sellPrice && pairTokenBalance?.gte(sellPrice)
                          ? "danger"
                          : "medium"
                      }
                      onClick={() => {
                        sellPrice &&
                          pairTokenBalance &&
                          pairTokenBalance.gte(sellPrice)
                          ? post({
                            color: "primary",
                            message: "Select NFT to sell",
                          })
                          : post({
                            color: "medium",
                            message: "Pool is out of funds to buy",
                          });
                      }}
                    >
                      {sellPrice &&
                        pairTokenBalance?.gte(sellPrice) &&
                        "sell to pool for"}
                      {formatTokenPrice(sellPrice || BigNumber.from(0))}
                      <IonAvatar>
                        <IonImg src={icons[currency]} />
                      </IonAvatar>
                    </IonChip>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              {nftInventory &&
                nftInventory.filter((x) => !nftIdsSell.includes(x.tokenId))
                  .length > 0 &&
                poolType !== '1' && (
                  <IonItem>
                    <IonButtons slot="start">
                      <IonLabel color="favorite">
                        <IonText color="primary">in wallet:</IonText>
                      </IonLabel>
                      {nftInventory &&
                        poolType !== '1' &&
                        nftInventory
                          .filter((x) => !nftIdsSell.includes(x.tokenId))
                          .filter(
                            (x) =>
                              !nftIdsSold.includes(BigNumber.from(x.tokenId))
                          )
                          .map((nft) => (
                            <IonAvatar
                              style={{ width: 50, height: 50 }}
                              key={nft.tokenId}
                              onClick={() => {
                                setNftIdsBuy([]);
                                if (
                                  sellPrice &&
                                  pairTokenBalance?.gte(sellPrice)
                                ) {
                                  setNftIdsSell([nft.tokenId]);
                                } else if (sellPrice &&
                                  pairTokenBalance?.lt(sellPrice)
                                ) {
                                  post({
                                    color: "danger", message: "Pool doesn't have enough liquidity to trade for this NFT", icon: walletOutline
                                  })
                                }

                              }}
                            >
                              <NFTImage
                                src={
                                  nft?.image ||
                                  (nft &&
                                    getFallbackImage(nft.address, nft.tokenId))
                                }
                              />
                            </IonAvatar>
                          ))}
                    </IonButtons>
                  </IonItem>
                )}
              {((token &&
                token !== "fantom" &&
                buyPrice &&
                nftIdsBuy.length !== 0) ||
                (sellPrice &&
                  nftIdsSell.length !== 0 &&
                  pairTokenBalance?.gte(sellPrice))) && (
                  <IonItem>
                    <>
                      {sellPrice &&
                        nftIdsSell.length !== 0 &&
                        pairTokenBalance?.gte(sellPrice) && (
                          <ApproveNFTButton
                            operator={routerAddress}
                            nft={collection}
                          />
                        )}
                    </>
                    {token &&
                      token !== "fantom" &&
                      buyPrice &&
                      nftIdsBuy.length !== 0 && (
                        <ApproveERC20Button
                          operator={routerAddress}
                          amount={buyPrice}
                          contract={token}
                        ></ApproveERC20Button>
                      )}
                  </IonItem>
                )}
              <IonItem>
                <IonButtons slot="start">
                  {!walletAddress &&
                    sellPrice &&
                    poolType !== '1' &&
                    pairTokenBalance.gte(sellPrice) && (
                      <IonChip
                        color={"danger"}
                        onClick={() => {
                          post({
                            color: "danger",
                            message: "Connect to paper hand",
                          });
                        }}
                      >
                        {!sm && "Sell for"}
                        {" " + formatTokenPrice(sellPrice)}
                        <IonAvatar style={{ width: 20, height: 20 }}>
                          <IonImg src={icons[currency]} />
                        </IonAvatar>
                      </IonChip>
                    )}

                  {walletAddress &&
                    sellPrice &&
                    nftIdsSell.length === 0 &&
                    poolType !== '1' &&
                    pairTokenBalance.gte(sellPrice) && (
                      <IonChip
                        color="danger"
                        onClick={() => {
                          nftInventory[0] &&
                            setNftIdsSell([nftInventory[0].tokenId]);
                          if (typeof nftInventory[0] === "undefined") {
                            post({
                              color: "danger",
                              message: "No NFTs to sell",
                            });
                          }
                        }}
                      >
                        <IonText>
                          {!sm && "Sell for"}
                          {" " + formatTokenPrice(sellPrice)}{" "}
                        </IonText>
                        <IonAvatar style={{ width: 20, height: 20 }}>
                          <IonImg src={icons[currency]} />
                        </IonAvatar>
                      </IonChip>
                    )}
                </IonButtons>

                <IonButtons slot="end">
                  {!walletAddress && buyPrice && (
                    <IonChip
                      color={"success"}
                      onClick={() => {
                        post({
                          color: "primary",
                          message: "Connect to purchase",
                        });
                      }}
                    >
                      {!sm && "Buy for"}
                      {" " + formatTokenPrice(buyPrice)}
                      <IonAvatar style={{ width: 20, height: 20 }}>
                        <IonImg src={icons[currency]} />
                      </IonAvatar>
                    </IonChip>
                  )}
                  {walletAddress &&
                    buyPrice &&
                    nftIdsBuy.length === 0 &&
                    poolType !== '0' && (
                      <IonChip
                        color={"success"}
                        onClick={() => {
                          if (currency === "$ftm") {
                            router
                              ?.swapETHForAnyNFTs(
                                [{ numItems: 1, pair }],
                                walletAddress,
                                walletAddress,
                                deadline,
                                { value: buyPrice }
                              )
                              .catch((message) => {
                                post({
                                  color: "danger",
                                  message: message.reason,
                                });
                              })
                              .then((tx) => {
                                postTx(
                                  tx,
                                  "Purchasing any nft from the pool",
                                  () => {
                                    window.location.reload();
                                  }
                                );
                              });
                          } else {
                            router
                              ?.swapERC20ForAnyNFTs(
                                [{ numItems: 1, pair }],
                                walletAddress,
                                walletAddress,
                                deadline
                              )
                              .catch((message) => {
                                if (
                                  message.reason.includes(
                                    "insufficient allowance"
                                  )
                                ) {
                                  setNftIdsBuy([heldIds[0]]);
                                  return;
                                }
                                post({
                                  color: "danger",
                                  message: message.reason,
                                });
                              })
                              .then((tx) => {
                                postTx(
                                  tx,
                                  "Purchasing with " + currency,
                                  () => {
                                    setNftIdsBuy([]);
                                  }
                                );
                              });
                          }
                          // router?.swapETHForSpecificNFTs(
                          //   [{ nftIds: nftIdsBuy, pair }],
                          //   walletAddress,
                          //   walletAddress,
                          //   deadline,
                          //   { value: buyPrice.mul(nftIdsBuy.length) },
                          // )
                        }}
                      >
                        <IonText>
                          {!sm && "Buy for"} {" " + formatTokenPrice(buyPrice)}{" "}
                        </IonText>
                        <IonAvatar style={{ width: 20, height: 20 }}>
                          <IonImg src={icons[currency]} />
                        </IonAvatar>
                      </IonChip>
                    )}
                </IonButtons>
              </IonItem>
            </IonList>
          )}
        </IonCardContent>
      </IonCard>
    </IonCol>
  );
};
export const PleaseConnectCard: React.FC = () => {
  const { location } = useHistory();
  return (
    <IonCard>
      <IonHeader>
        <IonItem>
          <IonTitle color={'danger'}>
            Please connect to view {location.pathname}
          </IonTitle>
        </IonItem>
      </IonHeader>
    </IonCard>
  );
};

export const PoolList: React.FC = () => {
  const { pairs, fetchPairs } = usePairFactory();
  const { address } = useAccount();
  useEffect(()=>{
  fetchPairs();
  },[fetchPairs])
  const { info } = usePair();
  const sm = window.innerWidth < 576;
  const md = window.innerWidth < 768;
  const [page, setPage] = useState(0);
  const pageSize = 10;
  if (Object.values(info).length === 0) {
    return <IonSpinner />
  }
  return (
    <IonGrid>
      <IonRow>
        {pairs.filter(x=>x!=="0x4B94a8BD742DAA1246cd24763b038e879460Bc49"&&x!=="0xdde43815ca0662910735f3b350d757f7a98f1475"&&x!=="0xfca736d62d5cafa374c3b106b1a28ac0f6eb2518"&&x!=="0x7d5af9bc1ce78b4d57074e53e7ae7227f24c54e6").slice(0, page * pageSize + pageSize).map((x) => {
          return <PoolInfo key={x} pair={x} pageSize={sm ? 3 : md ? 4 : 5} />;
        })}
      </IonRow>
      <IonRow>
        <IonCol size="12">
          {page * pageSize + pageSize < pairs.length && (
            <IonButton
              onClick={() => {
                setPage((x) => x + 1);
              }}
              expand="full"
            >
              Load More
            </IonButton>
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonInput,
  IonItem,
  IonChip,
  IonBadge,
} from '@ionic/react'
import { BigNumber, FixedNumber, ethers } from 'ethers'
import { formatEther } from 'ethers/lib/utils.js'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useAccount, useSigner } from 'wagmi'
import { ApproveNFTButton } from '../components/ApproveButton'
import { NFTImage } from '../components/NFTImage'
import { formatTokenPrice, tokens } from '../components/PoolList'
import { useERC20 } from '../hooks/useERC20'
import { useNotification } from '../hooks/useNotifications'
import { usePaintSwap } from '../hooks/usePaintSwap'
import { usePairRouter } from '../hooks/usePairRouter'
import { usePair } from '../hooks/usePairs'
import { useTransactionModal } from '../hooks/useTransactionModal'
import { PairEth__factory } from '../types/ethers-contracts/factories/PairEth__factory'
import { SendEtherButton } from '../components/SendEtherButton'

export const PoolAdmin: React.FC = () => {
  const params = useParams<{ address: string }>()
  const address = params && params.address.toLowerCase()
  const {
    inventory,
    collections,
    fetchCollectionInfo,
    getFallbackImage,
  } = usePaintSwap()
  const { address: walletAddress } = useAccount()
  const { initialize } = usePair()
  const { collection: nft, erc20: token, poolType } = usePair((x) => x.getInfo(address));
  useEffect(() => {
    walletAddress &&
      nft !== '' &&
      nft && fetchCollectionInfo(walletAddress, [nft.toLowerCase()])
  }, [nft, walletAddress, fetchCollectionInfo])
  const collectionInfo = collections.find(
    (x) => x.address === nft?.toLowerCase(),
  )
  const { data: signer } = useSigner()
  useEffect(() => {
    signer && initialize(address, signer)
  }, [address, signer, initialize])
  const instance = usePair((x) => x.instance[address])
  const router = usePairRouter((x) => x.router)
  const routerAddress = usePairRouter((x) => x.address)
  const initRouter = usePairRouter((x) => x.initialize)
  const heldIds = usePair((x) => x.heldIds[address])
  const owner = usePair((x) => x.heldIds[address])
  const tokenBalance = usePair((x) => x.tokenBalance[address])
  const delta = usePair((x) => x.delta[address])
  const spotPrice = usePair((x) => x.spotPrice[address])
  const connectERC20 = useERC20((x) => x.initialize)
  const [newSpotPrice, setSpotPrice] = useState<string>('')
  const [newLiquidity, setNewLiquidity] = useState<string>('')
  const [newOwner, setNewOwner] = useState<string>('')
  const [newDelta, setDelta] = useState<string>('')
  const [newFee, setFee] = useState<string>('')
  const tokenInstance = useERC20(x => x.instances[token])
  const [depositIds, setDepositIds] = useState<string[]>([])
  const nftInventory = (nft && inventory[nft?.toLowerCase()]) || []
  const { post: postTx } = useTransactionModal()
  const { post } = useNotification()
  useEffect(() => {
    signer && initRouter && initRouter(signer)
  }, [initRouter, signer])
  useEffect(() => {
    console.log(token);
    signer && token && connectERC20(token, signer)
  }, [connectERC20, signer, token])
  return (
    <IonContent>
      <IonCard>
        <IonItem color="light">
          <IonChip color="medium">
            {collectionInfo && collectionInfo?.name + ' '}Pool Admin
          </IonChip>
        </IonItem>
        <IonItem>
          <IonChip color="primary">Pool Type:</IonChip>
          {poolType && (
            <IonChip>
              {poolType === '0' && 'Buy only pool'}
              {poolType === '1' && 'Sell only pool'}
              {poolType === '2' && 'Trading pool'}
            </IonChip>
          )}
        </IonItem>
        <IonItem href={'https://ftmscan.com/address/' + address}>
          <IonChip color="primary">Pair:</IonChip>
          {address}
        </IonItem>
        <IonItem href={'https://ftmscan.com/address/' + nft}>
          <IonChip color="primary">Collection:</IonChip>
          <IonBadge>          {collectionInfo?.name}
          </IonBadge>
          {nft}
        </IonItem>
        <IonItem href={'https://ftmscan.com/address/' + token}>
          <IonChip color="primary">Token:</IonChip>
          {token && tokens[token.toLowerCase()]}
        </IonItem>
        <IonItem>
          <IonChip color="primary">
            <>
              SpotPrice:
              <IonBadge color={'tertiary'}>
                {formatTokenPrice(spotPrice)}
              </IonBadge>
            </>
          </IonChip>
          <IonInput
            onIonChange={(e) => {
              setSpotPrice(e.detail.value || '')
            }}
            type="number"
            placeholder="Adjust Spot Price"
          ></IonInput>
          <IonButton
            onClick={() => {
              instance.changeSpotPrice(

                BigNumber.from(FixedNumber.from(newSpotPrice).toHexString()),
              )
            }}
          >
            {' '}
            Set price
          </IonButton>
        </IonItem>
        <IonItem>
          <IonChip color="primary">
            <>
              Delta:
              <IonBadge color="tertiary">{formatTokenPrice(delta)}</IonBadge>
            </>
          </IonChip>
          <IonInput
            onIonChange={(e) => {
              setDelta(e.detail.value || '')
            }}
            type="number"
            placeholder="Adjust Price Delta"
          ></IonInput>
          <IonButton
            onClick={() => {
              instance.changeDelta(
                BigNumber.from(FixedNumber.from(newDelta).toHexString()),
              )
            }}
          >
            {' '}
            Set delta
          </IonButton>
        </IonItem>
        {poolType === '2' && (
          <IonItem>
            <IonChip color="primary">
              <>
                Fee</>
            </IonChip>

            <IonInput
              onIonChange={(e) => {
                setFee(e.detail.value || '')
              }}
              type="number"
              placeholder="Adjust Pool Fee% (90%-0%)"
            ></IonInput>
            <IonButton
              onClick={() => {
                instance
                  .changeFee(
                    BigNumber.from(newFee).mul(BigNumber.from(10).pow(16)),
                  )
                  .then((e) => {
                    postTx(e, 'Changing fee', () => {
                      window.location.reload()
                    })
                  })
              }}
            >
              {' '}
              Set Fee
            </IonButton>
          </IonItem>
        )}


        {poolType !== '0' && <IonItem>
          <IonChip color="primary">Deposit NFT:</IonChip>
          {depositIds.map((id) => (
            <IonAvatar>
              <NFTImage
                onClick={() => {
                  setDepositIds((x) => x.filter((y) => y !== id))
                }}
                src={getFallbackImage(nft, id)}
              />
            </IonAvatar>
          ))}
          {depositIds.length > 0 && router && (
            <ApproveNFTButton nft={nft} operator={routerAddress} />
          )}
          {depositIds.length > 0 && (
            <IonButton
              color="tertiary"
              onClick={() => {
                router!
                  .depositNFTs(nft, depositIds, address)
                  .then((e) => {
                    postTx(e, 'Depositing NFTs', () => {
                      window.location.reload()
                    })
                  })
                  .catch((message) => {
                    post({
                      color: 'danger',
                      message: message.reason,
                    })
                  })
              }}
            >
              Deposit
            </IonButton>
          )}
        </IonItem>}

        <IonItem>
          <IonChip color="primary">Inventory:</IonChip>
          {poolType !== '0' &&
            nftInventory
              .filter((y) => !depositIds.includes(y.tokenId))
              .map((nft) => (
                <IonAvatar>
                  <NFTImage
                    onClick={() => {
                      setDepositIds(
                        Array.from(new Set([...depositIds, nft.tokenId])),
                      )
                    }}
                    src={
                      nft.thumbnail ||
                      nft.image ||
                      getFallbackImage(nft.address, nft.tokenId)
                    }
                  />
                </IonAvatar>
              ))}
        </IonItem>
        <IonItem>
          <IonChip color="primary">
            <>
              Liquidity:
              <IonBadge color="tertiary">{formatEther(tokenBalance || 0)}</IonBadge>
            </>
          </IonChip>
          <IonInput
            onIonChange={(e) => {
              setNewLiquidity(e.detail.value || '')
            }}
            type="number"
            placeholder="Add liquidity"
          ></IonInput>


          {instance && newLiquidity && token === 'fantom' && <SendEtherButton chainId={250} to={instance.address} value={
            BigNumber.from(FixedNumber.from(newLiquidity).toHexString())} />}
          {tokenInstance && token !== 'fantom' && newLiquidity && <IonButton onClick={() => {
            tokenInstance.transfer(instance.address, BigNumber.from(FixedNumber.from(newLiquidity).toHexString())).then((tx) => {
              postTx(tx, "Sending", () => {
                window.location.reload();
              })
            })
          }}> Send Token</IonButton>}
        </IonItem>
        <IonItem>
          <IonChip color={'primary'}>Owner</IonChip>
          <IonInput
            onIonChange={(e) => {
              setNewOwner(e.detail.value || '')
            }}
            placeholder={"Transfer Ownership"}
          ></IonInput>
          {newOwner && ethers.utils.isAddress(newOwner) && <IonButton onClick={(() => {
            instance.transferOwnership(newOwner);
          })}>Transfer</IonButton>}
        </IonItem>

        {heldIds && heldIds.length > 0 && (
          <IonButton
            color="danger"
            onClick={() => {
              walletAddress &&
                instance.withdrawERC721(nft, heldIds).then((e) => {
                  postTx(e, 'Withdrawing NFTs', () => {
                    window.location.reload()
                  })
                })
            }}
          >
            Withdraw NFTS {heldIds && heldIds.length}
          </IonButton>
        )}
        {token !== 'fantom' &&
          walletAddress &&
          signer &&
          tokenBalance &&
          tokenBalance.gt(0) && (
            <IonButton
              color="danger"
              onClick={() => {
                instance.withdrawERC20(token, tokenBalance).then((e) => {
                  postTx(e, 'Withdrawing Tokens', () => {
                    window.location.reload()
                  })
                })
              }}
            >
              Withdraw Token {tokenBalance && formatEther(tokenBalance)}
            </IonButton>
          )}
        {token === 'fantom' &&
          walletAddress &&
          tokenBalance &&
          tokenBalance.gt(0) && (
            <IonButton
              color="danger"
              onClick={() => {
                PairEth__factory.connect(address, signer!)
                  .withdrawAllETH()
                  .then((e) => {
                    postTx(e, 'Withdrawing All FTM', () => {
                      window.location.reload()
                    })
                  })
              }}
            >
              Withdraw {tokenBalance && formatTokenPrice(tokenBalance)} FTM
            </IonButton>
          )}
      </IonCard>
    </IonContent>
  )
}

import { IonChip, IonIcon } from '@ionic/react'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils.js'
import { arrowForward } from 'ionicons/icons'
import { useEffect } from 'react'
import { Area, AreaChart, Legend, Tooltip, XAxis, YAxis } from 'recharts'
import { useSigner } from 'wagmi'
import { fromFloat, useBondingCurve } from '../hooks/useBondingCurve'
import { usePair } from '../hooks/usePairs'

export const PoolCurve: React.FC<{ pair: string }> = ({ pair }) => {
  const heldNfts = usePair((x) => x.heldIds[pair])
  const {poolType,bondingCurve:curveAddress} = usePair((x) => x.getInfo(pair))
  const delta = usePair((x) => x.delta[pair])
  const fee = usePair((x) => x.fee[pair])
  const spotPrice = usePair((x) => x.spotPrice[pair])
  const liquidity = usePair((x) => x.tokenBalance[pair])
  const { calculateCurveData, getCurveData } = useBondingCurve()
  const { data: signer } = useSigner()
  const curveData =
    signer &&
    fee &&
    spotPrice &&
    liquidity &&
    heldNfts &&
    getCurveData(
      curveAddress,
      heldNfts.length,
      delta,
      fee,
      spotPrice,
      liquidity,
      poolType.toString(),
    )
  useEffect(() => {
    signer &&
      fee &&
      spotPrice &&
      liquidity &&
      heldNfts &&
      liquidity &&
      calculateCurveData(
        signer,
        curveAddress,
        heldNfts.length,
        delta,
        fee,
        spotPrice,
        liquidity,
        poolType.toString(),
      )
  }, [
    calculateCurveData,
    curveAddress,
    delta,
    fee,
    heldNfts,
    liquidity,
    poolType,
    signer,
    spotPrice,
  ])
  if (!curveData) {
    return <></>
  }
  return (
    <>
      {' '}
      {curveAddress === '0xDD62964C856445496Baaf2C4B1EC224966415aC2' && (
        <IonChip>Linear Curve ↗</IonChip>
      )}
      {curveAddress === '0xB2D04Fe0a6cb72E85e087492d5f3E634a7B940e3' && (
        <IonChip>Exponential Curve ⤴</IonChip>
      )}
      <IonChip>{formatEther(delta||BigNumber.from("0"))} delta</IonChip>
      {poolType==='2'&&<IonChip>{fee && formatEther(fee.mul(100))}% Fee</IonChip>}
      <AreaChart
        width={window.innerWidth / 2}
        height={window.innerHeight / 2}
        data={Object.entries(curveData).map(([index, info]) => info)}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 20,
        }}
      >
        <XAxis dataKey="items" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Area
          stackId={'3'}
          type="monotone"
          dataKey="protocolFee"
          fill="#5260ff"
          stroke="#5260ff"
        />
        {poolType ==='2' && (
          <Area
            stackId={'0'}
            type="monotone"
            dataKey="lpSellFee"
            fill="darkRed"
            stroke="darkRed"
          />
        )}
        {poolType === '2' && (
          <Area
            stackId={'1'}
            type="monotone"
            dataKey="lpBuyFee"
            fill="darkgreen"
            stroke="darkgreen"
          />
        )}

        <Area
          stackId={'1'}
          type="monotone"
          dataKey="buyPrice"
          fill="#2dd36f"
          stroke="#2dd36f"
        />
        <Area
          stackId={'0'}
          type="monotone"
          dataKey="sellPrice"
          fill="#eb445a"
          stroke="#eb445a"
        />
      </AreaChart>
    </>
  )
}

import {
  IonAvatar,
  IonBadge,
  IonButtons,
  IonCard,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonItem,
  IonItemDivider,
  IonModal,
  IonRoute,
  IonRow,
  IonTitle,
} from '@ionic/react'
import { BigNumber } from 'ethers'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useAccount } from 'wagmi'
import { CreatePool } from '../components/CreatePool'
import { formatTokenPrice } from '../components/PoolList'
import { usePaintSwap } from '../hooks/usePaintSwap'
import { Page } from './Page'

export const PoolCreator: React.FC = () => {
  const { address } = useParams<{ address: string }>()
  const { inventory, collections, fetchCollectionInfo } = usePaintSwap()
  const { address: walletAddress } = useAccount()
  useEffect(() => {
    walletAddress && fetchCollectionInfo(walletAddress, [address])
  }, [address, walletAddress, fetchCollectionInfo])
  const collectionInfo = collections.find(
    (x) => x.address.toLowerCase() === address.toLowerCase(),
  ) || {
    image: '',
    name: '',
    banner: '',
  }
  return (
    <IonContent>
        <IonItem color={'tertiary'}>
          <IonTitle>Create a pool with {collectionInfo.name}</IonTitle>
          <IonButtons slot="end">
                      {(collectionInfo as any).stats?
                        <IonChip color="dark">
                          Paintswap floor: 
                          {formatTokenPrice(
                            BigNumber.from((collectionInfo as any).stats.floor || '0'),
                          )}{' '}FTM
                        </IonChip>:""
                      }
                    </IonButtons>

        </IonItem>
        <IonRow>
          <IonCol sizeSm="8" offsetSm="2">
            <IonImg
              style={{ height: 100 }}
              src={collectionInfo.banner}
            ></IonImg>
          </IonCol>
        </IonRow>
        <CreatePool nftAddress={address} />
      </IonContent>
  )
}

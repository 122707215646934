import { IonButton } from "@ionic/react"
import { BigNumber } from "ethers"
import { usePrepareSendTransaction, useSendTransaction } from "wagmi"

export const SendEtherButton:React.FC<{chainId:number,to:string,value:BigNumber}>=({chainId,to,value})=>{
    const {config}=usePrepareSendTransaction({chainId,request:{to,value}})
    const {sendTransaction}=useSendTransaction(config)
    return <IonButton onClick={()=>{
        sendTransaction&&sendTransaction();
    }}>
    Send FTM
    </IonButton>
}
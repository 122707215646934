import { IonButton } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useAccount, useSigner, useTransaction } from 'wagmi'
import { initialize } from 'workbox-google-analytics'
import { useERC721 } from '../hooks/useERC721'
import { useNotification } from '../hooks/useNotifications'
import { useTransactionModal } from '../hooks/useTransactionModal'

export const ApproveNFTButton: React.FC<{
  nft: string
  operator: string
}> = ({ nft, operator }) => {
  const { address } = useAccount()
  const { data: signer } = useSigner()
  const { initialize } = useERC721()
  const instance = useERC721((x) => x.instance[nft])
  const [approved, setApproved] = useState(false)
  const { post: postTx } = useTransactionModal()
  const { post } = useNotification()
  useEffect(() => {
    signer && initialize(nft, signer)
  }, [signer])
  useEffect(() => {
    address &&
      instance?.isApprovedForAll(address, operator).then((isApproved) => {
        setApproved(isApproved.valueOf())
      })
    address &&
      instance &&
      instance.on('Approval', () => {
        instance.isApprovedForAll(address, operator).then((isApproved) => {
          setApproved(isApproved.valueOf())
        })
      })
  }, [address, instance])
  return (
    <>
      {instance && !approved && (
        <IonButton
          onClick={() => {
            instance
              .setApprovalForAll(operator, true)
              .catch((message) => {
                post({ color: 'danger', message: message.reason })
              })
              .then((tx) => {
                postTx(tx, 'Approving Potionswap Router', () => {
                  setApproved(true)
                })
              })
          }}
        >
          Approve Potionswap Router to move NFTS
        </IonButton>
      )}
    </>
  )
}

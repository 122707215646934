import {
  IonCol,
  IonCard,
  IonCardTitle,
  IonItem,
  IonButtons,
  IonButton,
  IonText,
  IonIcon,
  IonChip,
  IonAvatar,
  IonImg,
  IonBadge,
  IonCardContent,
  IonProgressBar,
  IonSkeletonText,
  IonList,
  IonTitle,
  IonGrid,
  IonRow,
  IonContent,
  IonCardHeader,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { BigNumber } from "ethers";
import {
  shieldCheckmarkSharp,
  swapHorizontalOutline,
  arrowForward,
  arrowBack,
  settingsOutline,
  closeOutline,
  flameOutline,
} from "ionicons/icons";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSigner, useAccount, useBalance } from "wagmi";
import { useERC20 } from "../hooks/useERC20";
import { useERC721 } from "../hooks/useERC721";
import { useNotification } from "../hooks/useNotifications";
import { usePaintSwap } from "../hooks/usePaintSwap";
import { usePairRouter } from "../hooks/usePairRouter";
import { usePair } from "../hooks/usePairs";
import { useTransactionModal } from "../hooks/useTransactionModal";
import { ApproveNFTButton } from "./ApproveButton";
import { NFTImage } from "./NFTImage";
import { PleaseConnectCard, formatTokenPrice, icons, tokens } from "./PoolList";
import { PoolHistory } from "../pages/Pool";
import { useParams } from "react-router";

export const BurnPoolInfo: React.FC<{ pair: string; pageSize?: number }> = ({
  pair,
  pageSize = 4,
}) => {
  const { data: signer } = useSigner();
  const { initialize } = usePair();
  const {
    router,
    initialize: initRouter,
    address: routerAddress,
  } = usePairRouter();
  const pairA = pair.toLowerCase();
  const sellPrice = usePair((x) => x.getSellPrice(pairA));
  const heldIds = usePair((x) => x.heldIds[pairA]);
  const {collection,erc20:token,owner,poolType} = usePair((x) => x.getInfo(pairA));
  const { address: walletAddress } = useAccount();
  const [nftIdsSell, setNftIdsSell] = useState<string[]>([]);
  const [nftIdsBuy, setNftIdsBuy] = useState<BigNumber[]>([]);
  const [nftIdsPurchased, setNftIdsPurchased] = useState<BigNumber[]>([]);
  const [nftIdsSold, setNftIdsSold] = useState<BigNumber[]>([]);
  const connectERC20 = useERC20((x) => x.initialize);
  const { post } = useNotification();
  const { post: postTx } = useTransactionModal();
  const deadline = parseInt((new Date().getTime() / 1000).toFixed(0)) + 4200;
  const { initialize: connectErc721 } = useERC721();
  const { data: ftmBalance } = useBalance({ address: pair as any });
  const { instances } = useERC20();
  const tokenInstance = instances[token];
  const [pairTokenBalance, setPairTokenBalance] = useState<BigNumber>();
  useEffect(() => {
    signer && initialize(pairA, signer);
    signer && initRouter(signer);
  }, [signer, initRouter, initialize, pairA]);
  useEffect(() => {
    signer && collection && connectErc721(collection, signer);
  }, [signer, collection, connectErc721]);

  useEffect(() => {
    signer && token && token !== "fantom" && connectERC20(token, signer);
    if (token === "fantom") {
      ftmBalance && setPairTokenBalance(ftmBalance?.value);
    }
  }, [signer, token, connectERC20, ftmBalance]);
  useEffect(() => {
    signer &&
      tokenInstance &&
      tokenInstance.balanceOf(pairA).then((response) => {
        setPairTokenBalance(response);
      });
  }, [signer, tokenInstance, pairA]);

  useEffect(() => {
    signer &&
      tokenInstance &&
      tokenInstance.balanceOf(pairA).then((response) => {
        setPairTokenBalance(response);
      });
  }, [signer, tokenInstance, pairA]);

  const {
    inventory,
    fetchCollectionInfo,
    collections,
    getFallbackImage,
    fetchAddressInventory,
  } = usePaintSwap();
  const nftInventory = inventory[collection];
  const pairInventory = inventory[pairA + "-" + collection] || [];
  const currency = (token && tokens[token.toLowerCase()]) || "?";
  useEffect(() => {
    if (typeof nftInventory === "undefined" && collection && walletAddress) {
      fetchCollectionInfo(walletAddress, [collection]);
    }
  }, [walletAddress, collection, nftInventory, fetchCollectionInfo]);
  useEffect(() => {
    if (typeof nftInventory === "undefined" && collection && walletAddress) {
      fetchAddressInventory(pair, collection);
    }
  }, [
    pair,
    walletAddress,
    collection,
    nftInventory,
    fetchAddressInventory,
    fetchCollectionInfo,
  ]);
  const imgSize =
    window.innerWidth < 576
      ? "75px"
      : window.innerWidth > 768
      ? "150px"
      : "100px";

  const info = collections.find(
    (x) =>
      (x || { address: "" }).address.toLowerCase() ===
      (collection || "").toLowerCase()
  );
  const isOwner = walletAddress === owner;
  if (
    typeof collection === "undefined" ||
    typeof heldIds === "undefined" ||
    typeof pairTokenBalance === "undefined"
  ) {
    return (
      <IonCol sizeSm="12" sizeMd="6">
        <IonCard>
          <IonCardTitle>
            <IonItem color={"light"}>
              <IonButtons slot="start">
                {info && (
                  <IonButton
                    routerLink={"/pool/" + pair}
                    color="clear"
                    expand="full"
                  >
                    <IonText color="medium">
                      {heldIds?.length}
                      {"x"}
                    </IonText>
                    {info.verified && (
                      <IonIcon color="primary" icon={shieldCheckmarkSharp} />
                    )}
                    <IonText color="primary">{info.name} </IonText>
                    <IonIcon
                      color="dark"
                      icon={
                        poolType === '2'
                          ? swapHorizontalOutline
                          : poolType === '1'
                          ? arrowForward
                          : arrowBack
                      }
                    />{" "}
                    <IonItem color={"light"}>
                      <IonChip color="tertiary">
                        <IonAvatar>
                          <IonImg src={icons[currency]} />
                        </IonAvatar>
                        <IonText color="tertiary">
                          {pairTokenBalance &&
                            pairTokenBalance.gt(0) &&
                            formatTokenPrice(pairTokenBalance)}
                          {currency}{" "}
                        </IonText>
                      </IonChip>
                      <IonButtons slot="end">
                        {info.stats && info.stats.floor && (
                          <IonBadge color="light">
                            <IonText color="medium">
                              PS Floor:
                              {formatTokenPrice(
                                BigNumber.from(info.stats.floor)
                              )}
                              FTM
                            </IonText>
                          </IonBadge>
                        )}
                      </IonButtons>
                    </IonItem>
                  </IonButton>
                )}
              </IonButtons>
              {heldIds && (
                <IonButtons slot="end">
                  {isOwner && (
                    <IonButton
                      color="danger"
                      routerLink={"/admin/" + pair}
                      routerDirection="none"
                    >
                      <IonIcon icon={settingsOutline} />
                    </IonButton>
                  )}
                </IonButtons>
              )}
            </IonItem>
          </IonCardTitle>

          <IonCardContent>
            <IonProgressBar type="indeterminate" />
            <IonSkeletonText />
          </IonCardContent>
        </IonCard>
      </IonCol>
    );
  }
  if (heldIds.length === 0 &&sellPrice&& pairTokenBalance.lt(sellPrice)) {
return <IonCard>
<IonCardHeader>
<IonCardTitle color='warning'><IonIcon icon={flameOutline}/> Burn pool is out of {currency}!</IonCardTitle>
</IonCardHeader>
<IonCardContent>
<IonText>
  Refill coming soon!
</IonText>
<IonItem  href='https://twitter.com/Coktok' target="_new">
  <IonAvatar >
<IonImg src='https://pbs.twimg.com/profile_images/1536854569841528832/fz9ouPDx_400x400.jpg'/>
  </IonAvatar>
  Contact Coktok to refill this pool
  </IonItem>
<IonText>

</IonText>
</IonCardContent>
    </IonCard>;
  }
  return (
    <IonCol sizeSm="12" sizeMd="6">
      <IonCard>
        <IonCardTitle>
          <IonItem color={"light"}>
            <IonButtons slot="start">
              {info && (
                <IonButton
                  routerLink={"/pool/" + pair}
                  color="clear"
                  expand="full"
                >
                  <IonText color="medium">
                    {heldIds?.length}
                    {"x"}
                  </IonText>
                  {info.verified && (
                    <IonIcon color="primary" icon={shieldCheckmarkSharp} />
                  )}
                  <IonText color="primary">{info.name} </IonText>
                  <IonIcon
                    color="dark"
                    icon={
                      poolType === '2'
                        ? swapHorizontalOutline
                        : poolType === '1'
                        ? arrowForward
                        : arrowBack
                    }
                  />{" "}
                  <IonItem color={"light"}>
                    <IonChip color="tertiary">
                      <IonAvatar>
                        <IonImg src={icons[currency]} />
                      </IonAvatar>
                      <IonText color="tertiary">
                        {pairTokenBalance &&
                          pairTokenBalance.gt(0) &&
                          formatTokenPrice(pairTokenBalance)}
                        {currency}{" "}
                      </IonText>
                    </IonChip>
                    <IonButtons slot="end">
                      {info.stats && info.stats.floor && (
                        <IonBadge color="light">
                          <IonText color="medium">
                            PS Floor:
                            {formatTokenPrice(BigNumber.from(info.stats.floor))}
                            FTM
                          </IonText>
                        </IonBadge>
                      )}
                    </IonButtons>
                  </IonItem>
                </IonButton>
              )}
            </IonButtons>
            {heldIds && (
              <IonButtons slot="end">
                {isOwner && (
                  <IonButton
                    color="danger"
                    routerLink={"/admin/" + pair}
                    routerDirection="none"
                  >
                    <IonIcon icon={settingsOutline} />
                  </IonButton>
                )}
              </IonButtons>
            )}
          </IonItem>
        </IonCardTitle>
        <IonCardContent>
          {heldIds && (
            <IonList>
              <IonItem>
                <Swiper
                  slidesPerView={
                    heldIds.length > 5 ? 5 : heldIds.length - nftIdsBuy.length
                  }
                >
                  {heldIds &&
                    heldIds
                      .filter((x) => !nftIdsBuy.includes(x))
                      .filter((x) => !nftIdsPurchased.includes(x))
                      .map((id) => {
                        const nft = pairInventory.find(
                          (x) => x.tokenId === id.toNumber().toString()
                        );
                        return (
                          <SwiperSlide>
                            <div
                              style={{
                                borderRadius: "10px",
                                minHeight: imgSize,
                                minWidth: imgSize,
                                maxHeight: imgSize,
                                maxWidth: imgSize,
                              }}
                              key={id.toString()}
                              onClick={() => {
                                // if (nftIdsBuy.includes(id)) {
                                //   setNftIdsBuy(nftIdsBuy.filter((x) => x !== id))
                                // } else {
                                //   setNftIdsBuy(
                                //     Array.from(
                                //       new Set<BigNumber>([...nftIdsBuy, id]),
                                //     ),
                                //   )
                                // }
                                setNftIdsBuy([BigNumber.from(id)]);
                                setNftIdsSell([]);
                              }}
                            >
                              <NFTImage
                                src={
                                  nft?.thumbnail ||
                                  nft?.image ||
                                  getFallbackImage(
                                    collection,
                                    id.toNumber().toString()
                                  )
                                }
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                </Swiper>
              </IonItem>
              {nftIdsBuy.length > 0 && (
                <>
                  {nftIdsBuy &&
                    nftIdsBuy.map((id) => {
                      const nft = pairInventory.find((x) =>
                        id.eq(BigNumber.from(x.tokenId))
                      );
                      return (
                        <div
                          style={{
                            border: "2px solid #5bef96",
                            borderRadius: 3,
                          }}
                          key={id.toString()}
                        >
                          <IonCardTitle>
                            <IonItem>
                              <IonTitle color="success">
                                {info?.name} {nftIdsBuy.map((x) => "#" + x)}
                              </IonTitle>
                              <IonButtons slot="end">
                                <IonButton
                                  onClick={() => {
                                    setNftIdsBuy(
                                      nftIdsBuy.filter((x) => x !== id)
                                    );
                                  }}
                                >
                                  <IonIcon color="danger" icon={closeOutline} />
                                </IonButton>
                              </IonButtons>
                            </IonItem>
                          </IonCardTitle>
                          <IonGrid>
                            <IonRow>
                              <IonCol size="6">
                                <NFTImage
                                  onClick={() => {
                                    setNftIdsBuy(
                                      nftIdsBuy.filter((x) => x !== id)
                                    );
                                  }}
                                  style={{ height: 200 }}
                                  src={
                                    nft?.image ||
                                    getFallbackImage(collection, id.toString())
                                  }
                                />
                              </IonCol>
                              <IonCol size="6">
                                <IonCardContent>
                                  <IonTitle color="tertiary">
                                    {nft?.name}
                                  </IonTitle>
                                  <IonGrid>
                                    {nft?.attributes &&
                                      nft?.attributes
                                        .filter(
                                          (x) =>
                                            x.trait_type !== "Attributes Count"
                                        )
                                        .map((a) => (
                                          <IonChip>
                                            <IonBadge color="light">
                                              {a.trait_type}
                                            </IonBadge>
                                            {a.value}
                                          </IonChip>
                                        ))}
                                  </IonGrid>
                                </IonCardContent>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </div>
                      );
                    })}
                </>
              )}
              {nftIdsSell.length > 0 && (
                <div
                  style={{
                    border: "2px solid #eb445a",
                    borderRadius: 3,
                  }}
                >
                  <IonCard>
                    <IonCardTitle>
                      <IonTitle color="danger">
                        {info?.name} {nftIdsSell.map((x) => "#" + x)}
                      </IonTitle>
                    </IonCardTitle>
                    {nftIdsSell &&
                      nftIdsSell.map((id) => {
                        const nft = nftInventory.find(
                          (nft) => nft.tokenId === id
                        )!;
                        return (
                          <IonGrid key={id}>
                            <IonRow>
                              <IonCol>
                                <NFTImage
                                  onClick={() => {
                                    setNftIdsSell((x) =>
                                      x.filter((i) => id !== i)
                                    );
                                  }}
                                  style={{ height: 200 }}
                                  src={
                                    nft?.image ||
                                    getFallbackImage(collection, id.toString())
                                  }
                                />
                              </IonCol>
                              <IonCol>
                                <IonCardContent>
                                  <IonTitle color="tertiary">
                                    {nft?.name}
                                  </IonTitle>
                                  {nft &&
                                    nft.attributes &&
                                    nft?.attributes
                                      .filter(
                                        (x) =>
                                          x.trait_type !== "Attributes Count"
                                      )
                                      .map((a) => (
                                        <IonChip>
                                          <IonBadge color="light">
                                            {a.trait_type}
                                          </IonBadge>
                                          {a.value}
                                        </IonChip>
                                      ))}
                                </IonCardContent>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        );
                      })}
                  </IonCard>
                  {pairTokenBalance &&
                  walletAddress &&
                  sellPrice &&
                  nftIdsSell.length > 0 ? (
                    <IonButton
                      size="large"
                      expand="full"
                      color={"light"}
                      onClick={() => {
                        if (sellPrice && pairTokenBalance?.lte(sellPrice)) {
                          post({
                            color: "medium",
                            message: "Pool is out of funds to buy",
                          });
                          return;
                        }
                        router
                          ?.robustSwapNFTsForToken(
                            [
                              {
                                minOutput: nftIdsSell.length,
                                swapInfo: {
                                  nftIds: nftIdsSell.map(BigNumber.from),
                                  pair,
                                },
                              },
                            ],
                            walletAddress,
                            deadline
                          )
                          .catch((message) => {
                            post({
                              color: "danger",
                              message: message.reason.replaceAll("ETH", "FTM"),
                            });
                          })
                          .then((tx) => {
                            postTx(tx, "Burning nft", () => {
                              setNftIdsSold(nftIdsSell.map(BigNumber.from));
                              setNftIdsSell([]);
                            });
                          });
                      }}
                    >
                      <IonText color="danger">
                        {sellPrice &&
                          pairTokenBalance?.gte(sellPrice) &&
                          "Burn #" + nftIdsSell.map((x) => x)}
                      </IonText>
                      <IonChip
                        color={
                          sellPrice && pairTokenBalance?.gte(sellPrice)
                            ? "danger"
                            : "medium"
                        }
                      >
                        <IonAvatar>
                          <IonImg src={icons[currency]} />
                        </IonAvatar>
                        <IonText>
                          {formatTokenPrice(sellPrice.mul(nftIdsSell.length))}{" "}
                        </IonText>
                      </IonChip>
                    </IonButton>
                  ) : poolType !== '1' ? (
                    <IonChip
                      color={
                        sellPrice && pairTokenBalance?.gte(sellPrice)
                          ? "danger"
                          : "medium"
                      }
                      onClick={() => {
                        sellPrice &&
                        pairTokenBalance &&
                        pairTokenBalance.gte(sellPrice)
                          ? post({
                              color: "primary",
                              message: "Select NFT to burn",
                            })
                          : post({
                              color: "medium",
                              message: "Pool is out of funds to buy",
                            });
                      }}
                    >
                      {sellPrice &&
                        pairTokenBalance?.gte(sellPrice) &&
                        "burn for"}
                      {formatTokenPrice(sellPrice || BigNumber.from(0))}
                      <IonAvatar>
                        <IonImg src={icons[currency]} />
                      </IonAvatar>
                    </IonChip>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              <IonItem>
                <>
                  {sellPrice &&
                    nftIdsSell.length !== 0 &&
                    pairTokenBalance?.gte(sellPrice) && (
                      <ApproveNFTButton
                        operator={routerAddress}
                        nft={collection}
                      />
                    )}

                  {/* <IonItem>
                  {nftIdsSell.map((x) => {
                    const data = nftInventory.find((nft) => nft.tokenId === x)
                    return (
                      <IonAvatar
                        onClick={() => {
                          setNftIdsSell(nftIdsSell.filter((y) => y !== x))
                        }}
                      >
                        <IonImg src={data?.thumbnail} />
                      </IonAvatar>
                    )
                  })}
                </IonItem> */}
                </>
                <IonItem>
                  {walletAddress &&
                    sellPrice &&
                    nftIdsSell.length === 0 &&
                    poolType !== '1' &&
                    pairTokenBalance.gte(sellPrice) && (
                      <IonButton
                        color={"light"}
                        onClick={() => {
                          nftInventory[0] &&
                            setNftIdsSell((x) => [
                              ...x,
                              nftInventory[0].tokenId,
                            ]);
                          if (typeof nftInventory[0] === "undefined") {
                            post({
                              color: "danger",
                              message: "No NFTs to burn",
                            });
                          }
                        }}
                      >
                        Burn for
                        <IonChip color="danger">
                          {formatTokenPrice(sellPrice)}{" "}
                          <IonAvatar>
                            <IonImg src={icons[currency]} />
                          </IonAvatar>
                        </IonChip>
                      </IonButton>
                    )}
                </IonItem>
              </IonItem>
              <IonItem>
                <IonText>in wallet:</IonText>
                {nftInventory &&
                  poolType !== '1' &&
                  nftInventory
                    .filter((x) => !nftIdsSell.includes(x.tokenId))
                    .filter(
                      (x) => !nftIdsSold.includes(BigNumber.from(x.tokenId))
                    )
                    .map((nft) => (
                      <IonAvatar
                        key={nft.tokenId}
                        onClick={() => {
                          setNftIdsBuy([]);
                          if (sellPrice && pairTokenBalance?.gte(sellPrice)) {
                            setNftIdsSell((x) => [...x, nft.tokenId]);
                          }
                        }}
                      >
                        <NFTImage
                          src={
                            nft?.image ||
                            (nft && getFallbackImage(nft.address, nft.tokenId))
                          }
                        />
                      </IonAvatar>
                    ))}
              </IonItem>{" "}
            </IonList>
          )}
        </IonCardContent>
      </IonCard>
    </IonCol>
  );
};
export const Burn: React.FC = () => {
  const { address } = useAccount();
  const [pool, setPool] = useState<string>("")
  return (
    <IonContent>

      <IonCard>

        <IonSegment
          onIonChange={({ detail }) => {
            detail.value && setPool(detail.value)
          }}
        >
          <IonSegmentButton value={"0xfca736d62d5cafa374c3b106b1a28ac0f6eb2518"}>
            Baylien
            <IonAvatar>
              <IonImg src='https://media-nft.paintswap.finance/0xc2907ce44e44e7863d652e43b04c9857c7338e75_16_250.png' />
            </IonAvatar>
          </IonSegmentButton>
          <IonSegmentButton value={"0xd67d34aa547cb0cb42c41587a4fc38d7b4ed36d3"}>
            BBChicken
            <IonAvatar>

              <IonImg src='https://pbs.twimg.com/profile_images/1536854569841528832/fz9ouPDx_400x400.jpg' />
            </IonAvatar>
          </IonSegmentButton>
          <IonSegmentButton value={"0xA728d8eA536d9dEeAcb99e239DdBaC21e300f3E9"}>
            =BULL
            <IonAvatar>

              <IonImg src='https://media-nft.paintswap.finance/0xa42f7f70919d63400422bfb524457f7bdc33a9f9_235_250.png' />
            </IonAvatar>
          </IonSegmentButton>
        </IonSegment>
      </IonCard>

      {address && pool && (
        <BurnPoolInfo
          pair={pool}
          pageSize={12}
        />
      ) }
      {!address&&pool &&<PleaseConnectCard/>}
      {<PoolHistory
        pairs={["0xfca736d62d5cafa374c3b106b1a28ac0f6eb2518","0xd67d34aa547cb0cb42c41587a4fc38d7b4ed36d3","0xfE7aF4a4F73eb615E980E6617Fc6A2f100215555","0xdde43815ca0662910735f3b350d757f7a98f1475"]}
      ></PoolHistory>}
    </IonContent>
  );
};


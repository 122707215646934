import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonProgressBar,
  IonRow,
  IonText,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useSigner } from 'wagmi'
import { PoolCurve } from '../components/PoolCurve'
import { PoolInfo } from '../components/PoolList'
import { usePair } from '../hooks/usePairs'
import { SwapList } from './Swaps'

export const PoolHistory: React.FC<{ pairs: string[] }> = ({ pairs }) => {
  return (
    <IonCard>
      <SwapList pairs={pairs}/>
    </IonCard>
  )
}

const Pool: React.FC = () => {
  const { address } = useParams<{ address: string }>()
  return (
    <IonContent>
      <IonGrid>
        <IonRow>
          <IonCol sizeXs='0' sizeLg="3"></IonCol>
          <IonCol sizeLg="6" sizeXs='12'>
            <PoolInfo pair={address}></PoolInfo>
            <PoolCurve pair={address}></PoolCurve>
            <PoolHistory pairs={[address]}></PoolHistory>
          </IonCol>
          <IonCol sizeXs='0' sizeLg="3" />
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default Pool

import { IonItem } from '@ionic/react'
import { useEffect } from 'react'
import { useAccount } from 'wagmi'
import { usePairFactory } from '../hooks/usePairFactory'
import { Page } from './Page'

export const PoolDirectory: React.FC = () => {
  const wallet = useAccount()
  const { fetchPairs, initialize, pairs } = usePairFactory()
  useEffect(() => {
    fetchPairs()
  })
  return (
    <Page title={'directory'}>
      {pairs.map((pair) => (
        <IonItem>{pair}</IonItem>
      ))}
    </Page>
  )
}

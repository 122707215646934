import { BigNumber, Signer, ethers } from "ethers";
import { create } from "zustand";
import { Erc721__factory } from "../types/ethers-contracts/factories/Erc721__factory";

interface NFTInfo {
  nfts: [];
  id: string;
  address: string;
  tokenId: string;
  user: string;
  amount: string;
  locked: string;
  numOnSaleNonCustodial: string;
  numOnSaleAuction: string;
  numOnSaleCustodial: string;
  isNSFW: boolean;
  isTracked: boolean;
  lastTransferTimestamp: string;
  isERC721: boolean;
  isTransferable: boolean;
  creator: string;
  price: string;
  saleId: string;
}
interface UserCollectionInfo {
  id: string;
  user: string;
  address: string;
  total: string;
}
interface UserCollectionInfo {
  id: string;
  user: string;
  address: string;
  total: string;
}

interface CollectionInfo {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  address: string;
  owner?: string;
  name: string;
  description: string;
  nsfw?: boolean;
  mintPriceLow?: number;
  mintPriceHigh?: number;
  verified: boolean;
  startBlock?: number;
  path?: string;
  website?: string;
  twitter?: string;
  discord?: string;
  medium?: string;
  telegram?: string;
  reddit?: string;
  poster?: string;
  banner: string;
  thumbnail: string;
  marketing?: string;
  standard: string;
  featured?: boolean;
  displayed?: boolean;
  stats?: any;
  imageStyle?: string;
  customMetadata?: any;
  isFnft?: boolean;
  isInFnftMarketplace?: boolean;
  isReveal?: boolean;
  isSkipRank?: boolean;
  isDynamicMetadata?: boolean;
  isDynamicMedia?: boolean;
}
interface NFTAttribute {
  trait_type: string;
  value: string;
}

interface NFtMetadata {
  address: "0xe786aab7b220c1f9184ad406406806034785fc5c";
  amount: "1";
  attributes: NFTAttribute[];
  createdBlockNumber: number;
  description: string;
  external_uri: string;
  image: string;
  isERC721: boolean;
  isNSFW: boolean;
  name: string;
  thumbnail: string;
  tokenId: string;
  tokenURI: string;
  updated_at: number;
  user: string;
}

export type PaintswapTemplate = {
  ownedCollections: UserCollectionInfo[];
  collections: CollectionInfo[];
  signer?: Signer | ethers.providers.Provider;
  address: string;
  inventory: Record<string, NFtMetadata[]>;
  fallback: Record<string, NFtMetadata>;
  getFallbackImage: (address: string, id: string) => string;
  initialize: (
    address: string,
    signer: Signer | ethers.providers.Provider
  ) => void;
  getCollections:()=>void
  fetchAddressInventory: (address: string, collection: string) => void;
  fetchCollectionInfo: (address: string, collections: string[]) => void;
  fetchCollectionStats: (id: string) => void;
  fetchFallbackImage: (address: string, id: string) => void;
};

export const usePaintSwap = create<PaintswapTemplate>((set, store) => ({
  signer: undefined,
  address: "",
  collections: [
    {
      address: "0xd29ce66c111d54182d431ee897e72e194776bf02",
      banner: "",
      description: "",
      id: "",
      name: "TOCT",
      standard: "721",
      thumbnail: "",
      verified: true,
    }, {
      banner: "https://media-paint.paintswap.finance/SKULLYS_banner.jpg",
      address: "0x25ff0d27395a7aad578569f83e30c82a07b4ee7d",
      description: "Join the cult",
      id: "",
      name: "Skullys",
      standard: "721",
      thumbnail: "https://media-paint.paintswap.finance/SKULLYS_thumb.png",
      verified: true,
    },
    {
      address: "0x1b60b6daa371f5066bd8c1dc032627bf1f4e95df",
      banner:
        "https://storage.googleapis.com/cdn.nftkey.app/images/collections/collection-banner/popskullys-banner.png",
      description: "",
      id: "",
      name: "Pop Skullys",
      standard: "721",
      thumbnail:
        "https://storage.googleapis.com/cdn.nftkey.app/images/collections/collection-thumbnail/popskullys.png",
      verified: true,
    },
    {
      address: "0xa70aa1f9da387b815facd5b823f284f15ec73884",
      banner:
        "https://storage.googleapis.com/cdn.nftkey.app/images/collections/collection-banner/ftmfrogfamily-banner.png",
      description: "",
      id: "",
      name: "FTM Frog Family",
      standard: "721",
      thumbnail:
        "https://storage.googleapis.com/cdn.nftkey.app/nft-collections/ftmfrogfamily/0x0dfd42d6d75069b56a1e1f882c8a1bb59c505ee269fc7820885e69be8f0374fc-thumb.png",
      verified: true,
    },
    {
      address: "0x0754655d0f1733771c9169da666c289eeee4b938",
      banner: "",
      description: "",
      id: "",
      name: "FTM Elves",
      standard: "721",
      thumbnail: "https://ftmelves.com/artemis_small.png",
      verified: false,
    },
    {
      address: "0x4af7ad773e67eCF00299F7585caCc8ddbB62DC5C",
      banner: "",
      description: "",
      id: "",
      name: "Arcturians",
      standard: "721",
      thumbnail:
        "https://storage.googleapis.com/cdn.nftkey.app/images/collections/collection-thumbnail/arcturians.png",
      verified: false,
    },
    {
      address: "0x28908d5795b4d8f4cc175c63523f974412f2a5b1",
      name: "Shiba Punks",
      banner: "",
      description: "",
      id: "",
      standard: "",
      thumbnail: "",
      verified: false,
    },
    {
      address: "0x3396d660653612Ba06Ea9259316aad2E962F964c",
      name: "Cosmic frens",
      banner:
        "https://storage.googleapis.com/cdn.nftkey.app/images/collections/collection-banner/cosmicfrens-banner.png",
      description: "Cosmic frens",
      id: "cosmic-frens",
      thumbnail:
        "https://storage.googleapis.com/cdn.nftkey.app/images/collections/collection-thumbnail/cosmicfrens.png",
      verified: true,
      standard: "721",
      path: "0x3396d660653612Ba06Ea9259316aad2E962F964c",
    },
    {
      address: "0x3d7E7157459A352ada13ed8dA1Ba54a08A883965",
      name: "Astro Kids",
      banner:
        "https://storage.googleapis.com/cdn.nftkey.app/images/collections/collection-banner/astrokid-banner.png",
      description: "Astro Kids",
      id: "astro",
      standard: "721",
      verified: true,
      thumbnail:
        "https://storage.googleapis.com/cdn.nftkey.app/images/collections/collection-thumbnail/astrokid.png",
    },
    {
      address: "0x3d7E7157459A352ada13ed8dA1Ba54a08A883965",
      name: "Astro Kids",
      banner:
        "https://storage.googleapis.com/cdn.nftkey.app/images/collections/collection-banner/astrokid-banner.png",
      description: "Astro Kids",
      id: "astro",
      standard: "721",
      verified: true,
      thumbnail:
        "https://storage.googleapis.com/cdn.nftkey.app/images/collections/collection-thumbnail/astrokid.png",
    },
  ],
  ownedCollections: [],
  inventory: {},
  fallback: {},
  getFallbackImage: (address, id) => {
    const identifier = address + "-" + id;

    let extension = "png";
    if (
      address.toLowerCase() === "0xe260bed39020f969bd66b4e2ffcc3c5a34b46a41"
    ) {
      extension = "jpg";
    }
    if (typeof store().fallback[identifier] === "undefined") {
      set({
        fallback: {
          ...store().fallback,
          [identifier]: {
            address,
            tokenId: id,
            image:
              "https://media-nft.paintswap.finance/" +
              address.toLowerCase() +
              "_" +
              id +
              "_250." +
              extension,
            thumbnail: "",
          } as any,
        },
      });
      if (
        address.toLowerCase() ===
        "0xa70aa1f9da387b815Facd5B823F284F15EC73884".toLowerCase()
      )
        set({
          fallback: {
            ...store().fallback,
            [identifier]: {
              address,
              tokenId: id,
              image:
                "https://smol.mypinata.cloud/ipfs/QmepBFK4YT8KwB4GNg3pwBdtDJy8kr8RtPgURTBdqt8fV8/" +
                id +
                ".png",
              thumbnail: "",
            } as any,
          },
        });

      if (
        address.toLowerCase() ===
        "0x3396d660653612Ba06Ea9259316aad2E962F964c".toLowerCase()
      ) {
        store().fetchFallbackImage(address, id);
      }
    }
    return store().fallback[identifier]!.image;
  },
  fetchFallbackImage: (address, id) => {
    const NFT = Erc721__factory.connect(address, store().signer!);
    NFT.tokenURI(BigNumber.from(id)).then((uri) => {
      fetch(
        uri
          .replaceAll(
            "https://moonmasters.mypinata.cloud/",
            "https://smol.mypinata.cloud/"
          )
          .replaceAll("ipfs://", "https://smol.mypinata.cloud/ipfs/")
      ).then((result: any) => {
        result.json().then((metadata: any) => {
          const nftMetaData = {
            ...metadata,
            image: metadata.image
              .replace("ipfs://", "https://smol.mypinata.cloud/ipfs/")
              .replace(
                "https://moonmasters.mypinata.cloud/",
                "https://smol.mypinata.cloud/"
              ),
          };
          set({
            fallback: {
              ...store().fallback,
              [address + "-" + id]: nftMetaData,
            },
          });
        });
      });
    });
  },
  fetchCollectionStats: (address: string) => {
    const { path } = store().collections.find((x) => x.address === address) || {
      path: "",
    };
  },
  fetchCollectionInfo: (address, collections) => {
    address&&collections.forEach((selectedNFTAddress) => {
      const endpoint =
        "https://api.paintswap.finance/v2/userNFTs?numToSkip=0&numToFetch=200&user=" +
        address +
        "&collections%5B0%5D=" +
        selectedNFTAddress +
        "&orderBy=lastTransferTimestamp&orderDirection=desc";

      if (
        address &&
        typeof store().inventory[selectedNFTAddress] === "undefined"
      ) {
        fetch(endpoint).then((res) => {
          res.json().then(({ nfts }) => {
            set({
              inventory: {
                ...store().inventory,
                [selectedNFTAddress]: nfts,
              },
            });
          });
        });
      }
    });
  },
  fetchAddressInventory: (address, collection) => {
    const endpoint =
      "https://api.paintswap.finance/v2/userNFTs?numToSkip=0&numToFetch=200&user=" +
      address +
      "&collections%5B0%5D=" +
      collection +
      "&orderBy=lastTransferTimestamp&orderDirection=desc";
    if (
      address &&
      typeof store().inventory[address + "-" + collection] === "undefined"
    ) {
      fetch(endpoint).then((res) => {
        res.json().then(({ nfts }) => {
          set({
            inventory: {
              ...store().inventory,
              [address + "-" + collection]: nfts,
            },
          });
        });
      });
    }
  },
  getCollections:()=>{
    const endpoint =
      "https://api.paintswap.finance/v2/collections?numToSkip=0&includeUnapproved=false&isInFNFTMarketplace=false";
    fetch(endpoint).then((res) => {
      res.json().then((responseJSON) => {
        const collections = responseJSON.collections.filter(
          (x: any) => x.standard === "721"
        );

        set({
          collections: Array.from(
            new Set([...store().collections, ...collections])
          ),
        });
      });
    });
  },
  initialize: (address, signer) => {
    if (address) {
      set({ signer, address });
    }
    const ownedNFTs =
      "https://api.paintswap.finance/v2/userOwned?numToSkip=0&numToFetch=1000&orderBy=amount&orderDirection=desc&user=" +
      address;
    fetch(ownedNFTs).then((res) => {
      res.json().then(({ owned }) => {
        set({ ownedCollections: owned });
      });
    });

    const endpoint =
      "https://api.paintswap.finance/v2/collections?numToSkip=0&includeUnapproved=false&isInFNFTMarketplace=false";
    fetch(endpoint).then((res) => {
      res.json().then((responseJSON) => {
        const collections = responseJSON.collections.filter(
          (x: any) => x.standard === "721"
        );

        set({
          collections: Array.from(
            new Set([...store().collections, ...collections])
          ),
        });
      });
    });
  },
}));
